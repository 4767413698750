.imagetext-widget {
  display: flex;

  @include bp(max-width, $bp-large) {
    flex-direction: column;
  }

  .imagetext-image {
  }
  .imagetext-text {
    flex: 1;
    padding-left: 3rem;
    line-height: 2.5rem;

    @include bp(max-width, $bp-large) {
      padding-left: 0;
    }
  }

  .imagetext-wide {
    width: 100%;
  }
}