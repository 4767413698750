.brands-index-index{
  .page-title-wrapper {
    text-align: center;
    margin-bottom: 5rem;

    @include bp(max-width, $bp-small) {
      margin-bottom: 2rem;
      margin-top: 0;
      padding-top: 21px;
    }
  }

}
.brands-container {

  .fullsize-page-seo {
    min-height: 188px;
    border-bottom: 0;
    @include bp(max-width, $bp-medium) {
      @include bp(min-width, $bp-small) {
        padding-bottom: 3.7rem;
      }
    }
    @include bp(max-width, $bp-medium) {
      display: flex;
      align-items: flex-start;
      border-right: 0;
      border-left:0;
      padding-top: 4rem;
      h1 {
        width: 26%;
      }
      .fullsize-page-description {
        width: 74%;
      }
    }
    @include bp(max-width, $bp-small) {
      padding: 2.1rem 2.1rem 2.5rem;
    }
  }
}
  .brand-list-container{
    display:flex;
    flex-direction: column;
    border-right:1px solid $c-mercury;
    border-left:1px solid $c-mercury;
    @include bp(max-width, $bp-small) {
      border:0;
    }

    .letter-list{
      &:nth-child(1){
        border-top: 1px solid $c-mercury;
      }
      margin:0 auto;
      width:90%;
      border-bottom: 1px solid $c-mercury;
      padding-top:60px;
      padding-bottom: 60px;
      display:flex;
      flex-wrap: wrap;
      margin-bottom: 0;
      @include bp(max-width, $bp-medium) {
        padding-top: 3.2rem;
        padding-bottom:3rem;
      }
      @include bp(max-width, $bp-small) {
        padding-bottom: 0;
        padding-top: 0;
      }

      &:last-child{
        border-bottom: 0;
      }

      .letter{
        font-family: Hind;
        font-size: 6rem;
        color:$c-mine-shaft;
        position:relative;
        width:13%;
        @include bp(max-width, $bp-medium) {
           width:27%;
          line-height: 6rem;
        }
        @include bp(max-width, $bp-small) {
          @include font-size(3);
          width:22%;
          padding-top: 1.2rem;
        }

      }
      .brand-list{
        flex:6;
        display:flex;
        flex-wrap: wrap;
        @include bp(max-width, $bp-small) {
          padding-top:2.2rem;
        }
        .brand-list-element{
          width:20%;
          @include bp(max-width, $bp-xlarge) {
            width:33.333%;
          }
          @include bp(max-width, $bp-medium) {
            width:50%;
          }

          display:flex;
          flex-direction: row;
          align-items: center;
          padding-bottom: 30px;
          .brand-picture-container{
            flex:1;
            text-align: right;
            padding-right: 15%;
            @include bp(max-width, $bp-small) {
              padding-right:5%;
            }


            img{
              width:50px;
              height:auto;
              border:1px solid $c-mercury;
            }
          }
          .brand-name{
            flex:2;
        }
      }


    }
  }
}