.news-index-index{
  .page-title-wrapper {
    text-align: center;
    margin-bottom: 5rem;

    @include bp(max-width, $bp-small) {
      margin-bottom: 2rem;
      margin-top: 0;
      padding-top: 21px;

      .page-title{
        margin:0;
        .h1{
          font-weight: $bold-font-weight;
        }
      }
    }
  }
}
.news-view-details{
  @include bp(max-width, $bp-small) {
    main {
      margin-top: 0 !important;
    }
  }
  .breadcrumbs{
    display:none;
  }
  .page-title-wrapper {
    margin-bottom: 5rem;
    @include bp(max-width, $bp-small) {
      margin: 0 2rem 2rem 2rem;
      padding-top: 6.5rem;

      .page-title{
        margin:0;
        line-height: 18px;
        .base{
          font-weight: $bold-font-weight !important;
        }
      }
    }
  }
}
.news-title {
  font-weight: $bold-font-weight;
  @include font-size(2);
  width: 100%;
  display: block;
  color: $c-mine-shaft;
  margin-top: 2rem;

  @include bp(max-width, $bp-small) {
    @include font-size(1.2);
    margin-top:5px;
    padding-top:0;
  }
}
.news-date {
  @include font-size(1.2);
  color: $c-dusty-grey;

  @include bp(max-width, $bp-small) {
    @include font-size(1);
    display: block;
  }
}

.news-container {
  .news-subcontent-container{
    margin:0 auto;
    border-left:1px solid $c-alto;
    border-right:1px solid $c-alto;

    .subcontent{
      padding-top: 80px;
      padding-bottom: 80px;
      .subtitle, .content{
        width:75%;
        margin:0 auto;
      }
      .subtitle{
        margin-bottom: 30px;
      }
    }


    .subtitle{
      font-weight:$bold-font-weight;
      font-size: 1.8rem;
      color:$c-mine-shaft;
      text-transform:uppercase;
      margin-bottom: 10px;
    }
    .subcontent:nth-child(2){
      background-color: $c-concrete;
    }
  }

  @include bp(max-width, $bp-medium) {
    border-top: 1px solid $c-alto;
  }

  ul {
    list-style: disc;
  }
  .news-title-container {
    display: flex;
    justify-content: space-between;
    h1 {
      font-weight: $bold-font-weight;
      color: $c-mine-shaft;
      @include font-size(2.6);
      text-transform: uppercase;
      max-width: 60%;
      @include space('margin', 'left');
      @include space('margin', 'bottom');
      @include space('margin', 'top');
    }
  }

  .news-maincontent-container {
    display: flex;
    justify-content: space-between;
    border-left: 1px solid $c-mercury;
    border-top: 1px solid $c-mercury;

    .news-maincontent {
      padding: 7rem 0;
      line-height: 22px;
      letter-spacing: 0.06rem;
      box-sizing: border-box;
      flex: 1;
      color:$c-mine-shaft;
      @include space('padding', 'left');
      @include space('padding', 'right');
      @include bp(max-width, $bp-medium) {
        @include bp(min-width, $bp-small) {
          padding: 4rem 6rem;
        }
      }
      @include bp(max-width, $bp-small) {
        padding:2rem 1.9rem;
        line-height: 18px;
      }
    }

    img {
      width: 930px;
      height: 100%;
      display: block;
    }
  }

  .news-description-container {
    display: table;
    width: 100%;
    min-height: 264px;

    .news-description {
      background: $c-black;
      vertical-align: middle;
      display: table-cell;
      padding-left:6.5rem;
      .news-description-title {
        font-weight: $light-font-weight;
        color: $c-gray;
        @include font-size(4);
        margin: 0;
        line-height: 40px;
      }

      .news-description-subtitle {
        text-transform: uppercase;
        color: $c-white;
        font-weight: $bold-font-weight;
        @include font-size(1.8);
      }
    }

    .news-descriptioncontent {
      box-sizing: border-box;
      padding: 1.75rem 6rem;
      display: table-cell;
      vertical-align: middle;
      background: $c-congress-blue;
      color: $c-white;
      letter-spacing: 0.06em;
      width: 930px;
    }
  }

  .news-extracontent-container {
    background: $c-concrete;
    padding: 9.5rem 25.7rem;
    p {
      color: $c-mine-shaft;
    }
    iframe {
      @include space('margin', 'bottom');

    }
  }

  .news-zoomon-container {
    display: table;
    min-height: 350px;
    width: 100%;
    box-sizing: border-box;

    .news-zoomon-text {
      display: table-cell;
      box-sizing: border-box;
      height: 100%;
      background: $c-mine-shaft-dark;
      vertical-align: middle;
      letter-spacing: 0.06em;
      padding-left: 6.5rem;

      p {
        margin: 0;
      }

      .news-zoomon-title {
        font-weight: $light-font-weight;
        @include font-size(4);
        color: $c-tundora;
        line-height: 40px;
      }
      .news-zoomon-subtitle {
        font-weight: $bold-font-weight;
        color: $c-white;
        @include font-size(1.8);

      }
      .news-zoomon-content {
        color: $c-white;
        margin-top:3rem;
        margin-bottom:5rem;
      }
    }
    .news-zoomon-image {
      display: table-cell;
      width: 481px;

      img {
        display: block;
      }
    }
  }

  .news-extracontent-footer-container {
    display: flex;
    justify-content: flex-end;
    padding:8rem 0;
    border-left: 1px solid $c-mercury;
    border-right: 1px solid $c-mercury;

    .news-extracontent-footer {
      width: 60%;
      @include space('padding', 'right');
      strong {
        color: $c-mine-shaft;
      }

      .comafranc-button {
        box-shadow: 3px 2px 20px 0 $c-alto;
        margin-top:3rem;
      }
    }
  }

  @include bp(max-width, $bp-xlarge) {
    .news-maincontent-container {
      display: inline-block;
      border:none;
      .news-maincontent {
        width:100%;
      }
      img {
        width:100%;
      }
    }
    .news-extracontent-container {
      padding:0;
      iframe {
        width:100%;
      }
    }
  }
  @include bp(max-width, $bp-medium) {
    .news-description-container {
      display: inline-block;
      .news-description {
        display: inline-block;
        width:100%;
        @include space('padding', 'right');
        @include space('padding', 'left');
        @include space('padding', 'top');
        @include space('padding', 'bottom');
      }
      .news-descriptioncontent {
        @include space('padding', 'right');
        @include space('padding', 'left');
        @include space('padding', 'top');
        @include space('padding', 'bottom');
      }
    }
    .news-extracontent-container {
      background-color: $c-concrete;
      @include space('padding', 'top');
      padding-bottom: 270px;
      @include space('padding', 'right');
      @include space('padding', 'left');
      iframe {
        @include space('margin', 'top');
        @include space('margin', 'bottom');

      }
    }
    .news-zoomon-container {
      width:auto;
      display: inline-block;
      margin-top: -160px;

      .news-zoomon-image {
        display: none;

      }
      .news-zoomon-text {
        width:511px;
        display: inline-block;
        @include space('padding', 'right');
        @include space('padding', 'left');
        @include space('padding', 'top');
        @include space('padding', 'bottom');

      }
    }
    .news-extracontent-footer-container {
      @include space('padding', 'top');
    }
    .news-extracontent-footer-container .news-extracontent-footer {
      width:100%;
      @include space('padding', 'right');
      @include space('padding', 'left');
    }
  }
  @include bp(max-width, $bp-small) {
    .news-extracontent-container {
      @include space('padding', 'bottom');
      padding-right:0;
      padding-left:0;
      p {
        @include space('padding', 'right');
        @include space('padding', 'left');
      }
      p:nth-child(2) {
        padding-right:0;
        padding-left:0;
      }
    }
    .news-zoomon-container {
      margin-top: 0;
      .news-zoomon-image {
        display: block;
        width:100%;
      }
      img {
        width:100%;
      }
      .news-zoomon-text {
        width:100%;
        display: inline-block;
        @include space('padding', 'right');
        @include space('padding', 'left');
        @include space('padding', 'top');
        @include space('padding', 'bottom');
      }
    }

  }
}

// News list
.news-items-container {
  h1{
    margin:0 auto;
  }
  .news-items {
    border-left: 1px solid $c-mercury;
    border-right: 1px solid $c-mercury;

    ul {
      display: flex;
      list-style: none;
      flex-direction: column;
      padding: 0;
      margin: 0;

      .news-item-link{
        display:flex;
        @include bp(max-width, $bp-small) {
          flex-direction: column;
        }
        &:hover{
          text-decoration: none;
        }
      }

      .news-text{
        display:flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 6rem;
        @include bp(max-width, $bp-medium2) {
          @include bp(min-width, $bp-small) {
            padding: 2.7rem 4rem 2.2rem 4rem;
          }
        }
        @include bp(max-width, $bp-small) {
          padding:4.5rem
        }
      }

      img{
        display:block;
      }

      .news-item {
        width: 100%;
        display: table;
        background: $c-concrete;
        margin-bottom:0.1rem;
        color: $c-gray;

        @include bp(max-width, $bp-small) {
          margin-bottom: 1.6rem;
        }

        .news-title {
          margin-top: 0;

          @include bp(max-width, $bp-small) {
            @include font-size(1.2);
            margin-bottom: 0;
            padding-top: 0;
          }
        }

        &:last-child {
          margin-bottom:0;
        }

        .comafranc-button {
          margin-top:2rem;
          box-shadow: 0 0 20px 1px $c-mercury;
          width:110px;
          padding-right:0;
          padding-left:0;
          letter-spacing: 0.03rem;
          box-sizing: border-box;
          text-align: center;
          color: $c-congress-blue;

          @include bp(max-width, $bp-small) {
            display: none;
          }

          &:hover {
            color: $c-white;
          }
        }
      }
    }
  }
  &.fullsize-page .fullsize-page-seo {
    @include bp(min-width, $bp-medium) {
      padding-top: 5rem;
      padding-bottom: 5rem;
    }
    @include bp(max-width, $bp-medium) {
      @include space('padding', 'right');
      @include space('padding', 'left');
    }
    @include bp(max-width, $bp-medium) {
      @include bp(min-width, $bp-small) {
        padding: 4rem 6rem;
      }
    }
    @include bp(max-width, $bp-small) {
      flex-direction: column;
      align-items: flex-start;
      border-top: 1px solid $c-alto;
      padding-top: 2.6rem;
      padding-bottom: 2.5rem;
    }

    h1 {
      @include bp(max-width, $bp-medium) {
        @include bp(min-width, $bp-small) {
          width: 25%;
          margin-top: 0;
          padding-top: 0;
          padding-right: 25px;
        }
      }
      @include bp(max-width, $bp-small) {
        text-align: left;
        margin-bottom: 0.5rem;
      }
    }
  }
  .news-item-column {
    .index-news-description{
      color:$c-gray;
    }
    @include bp(max-width, $bp-medium) {
      width: 49.5%;
    }

    @include bp(max-width, $bp-small) {
      width:100%;
      display: inline-block;
    }

    > div {
      @include bp(max-width, $bp-small) {
        display: none;
      }
    }
  }
}

// Last news on homepage
.last-news {
  display: flex;
  color: $c-gray;
  border: 1px solid $c-mercury;
  margin-bottom: 0;
  align-items: center;

  @include bp(max-width, $bp-medium) {
    flex-direction: column;
  }

  .news-text{
    @include bp(max-width, $bp-medium) {
      @include bp(min-width, $bp-small) {
        padding: 4rem;
        .comafranc-button {
          margin-bottom: 0;
        }
      }
    }
  }

  @include bp(max-width, $bp-small) {
    border: none;
  }

  &:hover {
    color: $c-gray;
    text-decoration: none;
  }

  img {
    display: block;
  }

  .news-title {
    color: $c-mine-shaft;
    margin-top: 0.5rem;
  }

  .news-short-content { 
    line-height: 22px;
    color:$c-gray;

    @include bp(max-width, $bp-small) {
      line-height: 16px;
    }
  }

  .comafranc-button {
    box-shadow: 3px 2px 20px 0 $c-alto;
    margin-top:3.5rem;
    margin-bottom:2rem;
    @include bp(max-width, $bp-small) {
      margin-bottom:2rem;
      margin-top:2rem;
    }
  }
}

.news-item-column {
  display: table-cell;
  width: 50%;
  vertical-align: middle;

  @include bp(max-width, $bp-medium) {
    width: 100%;
    display: block;
    box-sizing: border-box;
  }
}

.news-text {
  @include space('padding', 'left');
  @include space('padding', 'right');
  padding-top: 15px;
  padding-bottom: 15px;

  @include bp(max-width, $bp-medium) {
    padding: 15px;
  }

  @include bp(max-width, $bp-small) {
    padding: 2.6rem 2.5rem;
  }
}

@include bp(min-width, $bp-xlarge) {
  .news-container .news-title-container h1 {
    margin-left: 0;
  }
  .back-link {
    margin-right: 0;
  }
}

@include bp(max-width, $bp-medium) {
  .last-news {
    background: none;
  }
}

// News details
@include bp(min-width, $bp-medium) {
  .news-view-details {
    .page-main {
      margin-top: 0;
    }
    .page-title-wrapper {
      margin-top: 6rem;
      .page-title {
        max-width: 80%;
      }
    }
  }

}