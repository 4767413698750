.page-title-wrapper {
  h1 {
    max-width: 80%;
    margin: 0;
    padding: 0;
    font-weight: $bold-font-weight;


    @include bp(max-width, $bp-medium) {
      max-width: none;
      display: inline-block;
    }
  }

  @include space('margin', 'bottom');
  @include space('margin', 'top');

  @include bp(max-width, $bp-xlarge) {
    margin-top: 11rem;
    @include space('margin', 'left');
    @include space('margin', 'right');
  }

  @include bp(max-width, $bp-medium) {
    margin-top: 6rem;
  }

  @include bp(max-width, $bp-small) {
    margin-top: 7rem;
    margin-bottom: 2rem;
  }

  .base {
    @include font-size(2.6);
    color: $c-mine-shaft;
    text-transform: uppercase;
    font-weight: $semibold-font-weight;


    @include bp(max-width, $bp-small) {
      @include font-size(1.5);
    }
  }
}

.comafranc-title {
  font-weight: $bold-font-weight;
  @include font-size(1.8);
  color: $c-mine-shaft;

  @include bp(max-width, $bp-small) {
    @include font-size(1.2);
  }
}

.h1 {
  font-weight: $bold-font-weight;
  @include font-size(2.6);
  color: $c-mine-shaft;
  text-transform: uppercase;
  margin-bottom: 3rem;
}

.h2 {
  font-weight: $bold-font-weight;
  @include font-size(2);
  color: $c-mine-shaft;
  margin: 0;
  padding-top: 2.5rem;
  padding-bottom: 2rem;

  @include bp(max-width, $bp-small) {
    @include font-size(1.2);
  }
}

select {
  @include bp(max-width, $bp-small) {
    @include font-size(1);
  }
}
