/* Colors - hint: use http://chir.ag/projects/name-that-color/ to name a color!  */
$c-rock-blue          : #99b6cd;
$c-congress-blue-light: #004481;
$c-congress-blue      : #004883;
$c-congress-blue-dark : #01447e;
$c-curious-blue       : #2a8be3;
$c-glacier            : #80A2C0;
$c-midnight-blue      : #01335f;
$c-prussian-blue      : #012d53;
$c-regal-blue         : #02396a;
$c-kashmir-blue       : #4e7597;
$c-midnight           : #002141;
$c-black              : #000000;
$c-concrete           : #f3f3f3;
$c-dusty-grey         : #9a9a9a;
$c-mercury            : #e2e2e2;
$c-mine-shaft         : #373737;
$c-mine-shaft-text    : #2D2D2D;
$c-mine-shaft-dark    : #232323;
$c-tundora            : #4c4c4c;
$c-gray               : #808080;
$c-white              : #FFFFFF;
$c-alto               : #d9d9d9;
$c-sylver-chalice     : #ababab;
$c-denim              : #106bbe;
$c-red                : #ec342e;
$c-silver             : #c7c7c7;
$c-cerulean           : #00AFEC;

/* Fonts */
$font-family: "Helvetica", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
$light-font-weight: 300;
$regular-font-weight: 400;
$medium-font-weight: 500;
$semibold-font-weight: 600;
$bold-font-weight: 700;

@font-face {
  font-family: 'Hind';
  src: url('../fonts/hind-light-webfont.woff2') format('woff2'),
  url('../fonts/hind-light-webfont.woff') format('woff');
  font-style: normal;
  font-weight: $light-font-weight;
}
@font-face {
  font-family: 'Hind';
  src: url('../fonts/hind-regular-webfont.woff2') format('woff2'),
  url('../fonts/hind-regular-webfont.woff') format('woff');
  font-style: normal;
  font-weight: $regular-font-weight;
}
@font-face {
  font-family: 'Hind';
  src: url('../fonts/hind-medium-webfont.woff2') format('woff2'),
  url('../fonts/hind-medium-webfont.woff') format('woff');
  font-style: normal;
  font-weight: $medium-font-weight;
}
@font-face {
  font-family: 'Hind';
  src: url('../fonts/hind-semibold-webfont.woff2') format('woff2'),
  url('../fonts/hind-semibold-webfont.woff') format('woff');
  font-style: normal;
  font-weight: $semibold-font-weight;
}
@font-face {
  font-family: 'Hind';
  src: url('../fonts/hind-bold-webfont.woff2') format('woff2'),
  url('../fonts/hind-bold-webfont.woff') format('woff');
  font-style: normal;
  font-weight: $bold-font-weight;
}

/* Breakpoints */
$bp-xsmall: 340px;
$bp-small: 640px;
$bp-medium: 768px;
$bp-medium2: 769px;
$bp-large: 992px;
$bp-xlarge: 1200px;
$bp-xxl: 1450px;

$mq-support: true !default;
$mq-fixed-value: 1024px !default;


@mixin bp($feature, $value) {
  // Set global device param
  $media: only screen;

  // Media queries supported
  @if $mq-support == true {

    @media #{$media} and ($feature: $value) {
      @content;
    }

    // Media queries not supported
  } @else {

    @if $feature == 'min-width' {
      @if $value <= $mq-fixed-value {
        @content;
      }
    } @else if $feature == 'max-width' {
      @if $value >= $mq-fixed-value {
        @content;
      }
    }

  }
}


$margin-xsmall: 3rem;
$margin-small: 3rem;
$margin-medium: 3rem;
$margin-large: 5rem;
$margin-xlarge: 6.2rem;
$margin-max: 6.2rem;

@mixin space($wording, $direction) {
  @include bp(max-width, $bp-xlarge) {
    @if $direction == top {
      #{$wording}-#{$direction}: $margin-large;
    } @else if $direction == bottom {
      #{$wording}-#{$direction}: $margin-large;
    } @else if $direction == left {
      #{$wording}-#{$direction}: $margin-xlarge;
    } @else {
      #{$wording}-#{$direction}: $margin-xlarge;
    }
  }
  @include bp(max-width, $bp-large) {
    @if $direction == top {
      #{$wording}-#{$direction}: $margin-medium;
    } @else if $direction == bottom {
      #{$wording}-#{$direction}: $margin-medium;
    } @else if $direction == left {
      #{$wording}-#{$direction}: $margin-large;
    } @else {
      #{$wording}-#{$direction}: $margin-large;
    }
  }
  @include bp(max-width, $bp-medium) {
    @if $direction == top {
      #{$wording}-#{$direction}: $margin-small;
    } @else if $direction == bottom {
      #{$wording}-#{$direction}: $margin-small;
    } @else if $direction == left {
      #{$wording}-#{$direction}: $margin-medium;
    } @else {
      #{$wording}-#{$direction}: $margin-medium;
    }
  }
  @include bp(max-width, $bp-small) {
    @if $direction == top {
      #{$wording}-#{$direction}: $margin-xsmall;
    } @else if $direction == bottom {
      #{$wording}-#{$direction}: $margin-xsmall;
    } @else if $direction == left {
      #{$wording}-#{$direction}: $margin-small;
    } @else {
      #{$wording}-#{$direction}: $margin-small;
    }
  }
  @include bp(max-width, $bp-xsmall) {
    #{$wording}-#{$direction}: $margin-xsmall;
  }
  @include bp(min-width, $bp-xlarge) {
    @if $direction == top {
      #{$wording}-#{$direction}: $margin-large;
    } @else if $direction == bottom {
      #{$wording}-#{$direction}: $margin-large;
    } @else if $direction == left {
      #{$wording}-#{$direction}: $margin-xlarge;
    } @else {
      #{$wording}-#{$direction}: $margin-xlarge;
    }
  }
}

