.comafranc-local-configurator-index {
  .page-title-wrapper {
    text-align: center;
  }
}
.configurator-container {
  display: flex;
  justify-content: space-between;
  margin-top:5.5rem;
  position: relative;

  .configurator-column {
    position: relative;
    .configurator-content {
      display: flex;
      align-items: center;
    }

    img {
      display: block;
    }

    &.column-left {
      .configurator-title {
        padding-left: 4rem;
      }
    }
    &.column-right {
      .configurator-title {
        padding-right: 4rem;
      }

      .configurator-content {
        justify-content: flex-end;
      }

      .configurator-link {
        left: 10px;
        right: auto;
      }
    }

    .configurator-title {
      transform: translate(0, -50%);
      position: absolute;
      color: $c-white;
      font-weight:$semibold-font-weight;
      @include font-size(1.8);
    }
  }

  .configurator-link {
    position: absolute;
    display: none;
    right: 10px;
    top: 50%;
    transform: translate(0, -100%);
  }

  .configurator-hover {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: $c-white;
    visibility: hidden;
    display: flex;
    justify-content: center;
    flex-direction:column;
    padding-left:10rem;
    padding-right:10rem;
    box-sizing: border-box;

    .configurator-hover-title {
      @include font-size(1.8);
      color: $c-mine-shaft;
      font-weight: $light-font-weight;
      padding-bottom:2rem;
    }
    .configurator-hover-description {
      color: $c-gray;
    }
  }

  .choose-universe {
    text-align: center;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    background: $c-white;
    padding: 5.5rem 5.5rem 5rem;
    max-width: 405px;

    .choose-universe-title {
      margin-bottom: 2.5rem;
      font-weight: $bold-font-weight;
      @include font-size(1.8);
      color: $c-mine-shaft;
      width:100%;
      display: block;
    }
    .choose-universe-description {
      line-height: 22px;
      font-weight: $medium-font-weight;
      color: $c-gray;
    }
  }
}
.configurator-footer {
  display: flex;
  padding: 6rem 6.3rem;
  border-left: 1px solid $c-mercury;
  border-right: 1px solid $c-mercury;

  .configurator-footer-title {
    flex: 1;
    padding-top: 0;
    @include font-size(1.8);
  }

  .configurator-footer-description {
    flex: 2;
  }
}