.catalogrequest-index-index {
  .page-title-wrapper {
    text-align: center;
    margin-bottom: 5rem;

    @include bp(max-width, $bp-small) {
      margin-bottom: 2rem;
      margin-top: 0;
      padding-top: 21px;
    }
  }
}
.home-catalogrequest-title{
  font-size: 30px;
  color:$c-mine-shaft;
  font-weight:$bold-font-weight;
}

.slider-description-nbshop{
  font-size: 30px;
  font-weight:$bold-font-weight;
}
.slider-description{
  .slider-description-content{
    font-weight: $light-font-weight;
    font-size: 14px;
  }
}

form.catalogrequest{
  display:flex;
  .catalogrequest-img{

    margin:0 auto;
    img{
      @include bp(min-width, $bp-large) {
        min-width: 700px;
      }
      display: block;
    }
  }
  .catalogrequest-form-container{
    flex:1;
    textarea{
      margin-bottom: 25px;
    }
    @include bp(min-width, $bp-medium) {
      textarea{
        margin-bottom: 80px;
      }
    }

  }

  @include bp(max-width, $bp-xlarge) {
    flex-direction: column;

    .catalogrequest-form-container{
      border-top: 1px solid $c-mercury;
    }

  }
}
.catalogrequest-top {
  display: table;

  @include bp(max-width, $bp-small) {
    display: flex;
    flex-direction: column;
  }

  .catalogrequest-list {
    display: table-cell;
    vertical-align: top;
    background: $c-black;
    padding: 6rem 11.5rem 0 6.3rem;
    box-sizing: border-box;

    @include bp(max-width, $bp-large) {
      padding-left: 4.5rem;
      padding-right: 4.5rem;
      width: 50%;
    }

    @include bp(max-width, $bp-small) {
      width: 100%;
    }

    ul {
      padding: 0;

      @include bp(max-width, $bp-small) {
        display: flex;
        flex-wrap: wrap;
      }

      li {
        margin-bottom: 2.2rem;

        @include bp(max-width, $bp-small) {
          width: 50%;
          display: flex;
          align-items: center;
        }
      }
    }

    > div {
      text-transform: uppercase;
      color: $c-white;
      font-weight: $bold-font-weight;
      @include font-size(1.8);
      margin-bottom: 5rem;

      @include bp(max-width, $bp-small) {
        @include font-size(2.4);
      }
    }

    .comafranc-checkbox label {
      background: $c-black;
    }
    .comafranc-checkbox-label {
      letter-spacing: 0.06rem;
    }
  }

  .catalogrequest-images {
    width: 965px;
    display: table-cell;
    vertical-align: top;

    @include bp(max-width, $bp-large) {
      width: 50%;
    }

    @include bp(max-width, $bp-small) {
      width: 100%;
    }

    ul {
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      margin-bottom:0;
      justify-content: space-between;

      li {
        width: 321px;
        margin-bottom: 0.1rem;

        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6) {
          margin-bottom:0;
        }

        @include bp(max-width, $bp-large) {
          width: 50%;
        }

        @include bp(max-width, $bp-small) {
          width: 33.3333333%;
        }

        img {
          display: block;
          opacity: 0.4;
          cursor: pointer;

          &:hover, &.active, &.mouseenter {
            opacity: 1;
          }
        }
      }
    }
  }
}
.catalogrequest-form-container {
  border-right: 1px solid $c-mercury;
  border-left: 1px solid $c-mercury;
  border-top: 1px solid $c-mercury;
  padding-top: 5rem;
  position:relative !important;

  @include bp(max-width, $bp-large) {
    border-left: 1px solid $c-mercury;
  }
  @include bp(max-width, $bp-small) {
    border:0;
    padding-top:3.2rem;
  }
  .catalogrequest-img{
    border:0;
    .banner-img{
      @include bp(max-width, $bp-large) {
        width:100%;
      }
    }
  }

  .catalogrequest-form {
    color: $c-mine-shaft;

    @include bp(max-width, $bp-small) {
      width: 90%;
      margin:0 auto;
      border:0;
    }
    @include bp(max-width, $bp-medium) {
      @include bp(min-width, $bp-small) {
        padding-left: 4rem;
        padding-right: 4rem;
      }
    }

    .info-form-container{
      width: 80%;
      margin: 0 auto;
      @include bp(max-width, $bp-medium) {
        width: 100%;
      }
      .info-form{
        font-weight:$bold-font-weight;
        font-size:18px ;
        color:$c-mine-shaft;
        margin:0;
        @include bp(max-width, $bp-small) {
          @include font-size(1.2);
        }
      }
      .info-form2{
        font-weight: $medium-font-weight;
        font-size:14px;
        color:$c-gray;
        margin-bottom:52px;
        @include bp(max-width, $bp-small) {
          @include font-size(1);
          margin-bottom:30px;
        }
      }
    }
    .fieldset {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 75%;
      margin: 0 auto;

      @include bp(max-width, $bp-medium) {
        width: 100%;
      }

      &:first-child {
        @include bp(max-width, $bp-medium) {
          margin-bottom: 2.6rem;
        }
      }

    }

    .field {
      display:inline-block;

      textarea {
        height: 196px;
        @include bp(max-width, $bp-large) {
          margin-bottom: 0;
        }
        @include bp(max-width, $bp-medium) {
          height: 16rem !important;
          margin-bottom: 4.8rem ;
        }
        @include bp(max-width, $bp-small) {
          height: 110px;
          width:100% !important;
          margin-bottom: 2.5rem ;
        }
      }
      input {
        color: $c-congress-blue;
        &:focus {
          border-color: $c-congress-blue;
        }
      }
    }

    .comafranc-checkbox {
      label {
        background: $c-white;

        &:after {
          background: $c-mine-shaft;
        }
      }
    }
    .comafranc-checkbox-label {
      margin-left: 1.5rem;

      @include bp(max-width, $bp-small) {
        margin-left: 0;
      }

      &:hover, &.active {
        color: $c-mine-shaft;
      }
    }
  }
  .subscribe-newsletter-container {
    letter-spacing: 0.06rem;
  }
  .actions-toolbar {
    position:absolute;
    bottom:0;
    width:100%;

    @include bp(max-width, $bp-medium) {
      position:static;
    }
  }
  .form-hint {
    margin: 4rem 0 6.5rem;
    letter-spacing: 0.06rem;
  }
}
.civility-container {
  display: flex;
  height: 48px;
  margin-bottom: 2.6rem;
  align-items: center;

  @include bp(max-width, $bp-small) {
    justify-content: space-between;
  }

  > div {
    margin-right: 9rem;

    @include bp(max-width, $bp-small) {
      margin-right: 0;
    }
  }
}