.contact-index-index {
  .page-title-wrapper {
    text-align: center;
    margin-bottom: 5rem;

    @include bp(max-width, $bp-small) {
      margin-bottom: 2rem;
      margin-top: 0;
      padding-top: 21px;
    }
  }
}
.contact-page {
  display: flex;
  picture img{
    @include bp(min-width, $bp-large) {
      min-width: 700px;
    }
  }
  @include bp(max-width, $bp-xlarge) {
    flex-direction: column;
  }

  > div {
    flex: 1;
  }

  img {
    display: block;

    @include bp(max-width, $bp-medium) {
      width: 100%;
    }
    @include bp(max-width, $bp-xlarge) {
      margin:0 auto;
    }
  }
  &:nth-child(1){
    margin:0 auto;
  }
   
  .form {
    position: relative;
    border-right: 1px solid $c-mercury;
    border-top: 1px solid $c-mercury;
    height: 100%;
    display: inline-table;

    .fieldset {

      @include bp(max-width, $bp-small) {
        .legend{
          @include font-size(1.2);
        }
        .note{
          @include font-size(1);
        }

      }
      @include bp(min-width, $bp-small) {
        @include bp(max-width, $bp-medium) {
          margin-bottom: 0;
        }
      }
      display: flex;
      flex-wrap: wrap;
      @include space('padding', 'left');
      @include space('padding', 'right');
      justify-content: space-between;
      margin-top: 6rem;
      @include bp(max-width, $bp-small) {
        margin-top: 3rem;
        margin-bottom: 1.8rem;
      }
    }
    .catalog-form-container{
      display:flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 60px;

      @include bp(max-width, $bp-medium) {
        margin-bottom: 2.8rem;
      }

      @include bp(max-width, $bp-small) {
        margin-bottom: 0;
      }

      img{
        flex:1;
        padding-right: 25px;
        @include bp(min-width, $bp-small) {
          @include bp(max-width, $bp-medium) {
            height:86px;
            width:auto;
          }
        }
        @include bp(max-width, $bp-small) {
          width:80%;
        }
      }
      .checkbox-catalog{
        flex:3;
      }
    }
  }

  @include bp(max-width, $bp-xlarge) {

    .form .actions-toolbar {
      position: relative;
      @include bp(max-width, $bp-small) {
        height:45px;
        button{
          padding:0;
          line-height: 45px;
          height:45px;
        }
      }
    }
    .form{
      border-left:1px solid $c-mercury;
    }
  }

  @include bp(max-width, $bp-medium) {
    & > div {
      width: 100%;
      overflow: hidden;
    }
  }
  @include bp(max-width, $bp-small) {
    .form .field {
      width:100%;
    }
    & > div {
      display: inline-block;
      width: 100%;
      height: auto;
    }
  }
}
.contact-index-index {
  @include bp(max-width, $bp-medium) {
    .h1 {
      text-align: center;
    }
  }
  .civility-container {
    color:$c-mine-shaft;
    margin-bottom: 1.5rem;

    @include bp(max-width, $bp-small) {
      width: 100%;
      justify-content: space-between;
    }

    > div {
      @include bp(max-width, $bp-small) {
        flex: 1;
        margin: 0;
      }
    }

    .comafranc-checkbox {
      label {
        background: $c-white;

        &:after {
          background: $c-mine-shaft;
        }
      }
    }

    .comafranc-checkbox-label {
      &:hover {
        color:$c-mine-shaft;
      }
    }
  }
}
