.appointment-index-index {
  .page-title-wrapper {
    text-align: center;
  }
}
.select-subject {
  text-align: center;

  @include bp(max-width, $bp-small) {
    text-align: left;
    @include space('padding', 'left');
    @include space('padding', 'right');
    margin-top: 2rem;
  }

  select {
    color: $c-gray;
    height: 48px;
    width: 40%;
    margin: 0 auto;
    border-radius: 50px;
    -moz-appearance: none;

    @include bp(max-width, $bp-large) {
      width:80%;
      margin: 0 auto;
    }

    @include bp(max-width, $bp-small) {
      @include font-size(1);
      width: 100%;
      height:auto;
    }
  }
}
.centered-label {
  width: 100%;
  display: block;
  margin-bottom: 1rem;
}
.appointment-expo-room {
  border-top: 1px solid $c-alto;
  margin-top: 6rem;
  text-align: center;
  padding-top: 3rem;

  @include bp(max-width, $bp-small) {
    margin-top: 2.6rem;
    padding-top: 2rem;
  }

  ul {
    padding-left:0;
    display: flex;
    margin-bottom: 0;
    margin-top: 2rem;

    li {
      display: flex;
      flex-wrap: wrap;
      width: 20%;
      margin-bottom: 0;

      @include bp(max-width, $bp-large) {
        width:33.33333%;
      }

      @include bp(max-width, $bp-small) {
        width:50%;
        flex-direction: column;
        flex-wrap: nowrap;
      }

      @include bp(max-width, $bp-xsmall) {
        width:100%;
      }

      .expo-room-image {
        position: relative;

        @include bp(min-width, $bp-large) {
          height: 179px;
        }

        @include bp(min-width, $bp-xlarge) {
          height: 261px;
        }

        &:after {
          content: '';
          position: absolute;
          top: 0; right: 0;
          bottom: 0; left: 0;
          background: $c-congress-blue-light;
          opacity: 0.7;
        }
      }

      .expo-room-selection {
        text-align: left;
        padding: 2.8rem 0 2rem 2.5rem;
        width: 100%;
        border-left: 1px solid $c-mercury;
        border-bottom: 1px solid $c-mercury;

        @include bp(max-width, $bp-small) {
          display: flex;
          align-items: center;
        }

        @include bp(min-width, $bp-large) {
          min-height: 89px;
        }

        @include bp(min-width, $bp-xlarge) {
          min-height: inherit;
        }
      }

      &:last-child {
        .expo-room-selection {
          border-right: 1px solid $c-mercury;
        }
      }

      img {
        display: block;

        @include bp(max-width, $bp-large) {
          width:100%;
        }
      }
    }
  }

  .comafranc-checkbox {
    label {
      background: $c-white;

      &:after {
        background: $c-mine-shaft;
      }
    }
  }
  .comafranc-checkbox-label {
    &:hover, &.active {
      color: $c-mine-shaft;
    }
  }
  @include bp(max-width, $bp-large) {
    ul {
      flex-wrap: wrap;
    }
  }
}
.come-from-pro-container {
  display: flex;
  height: 48px;
  margin-bottom: 2.6rem;
  align-items: center;

  @include bp(max-width, $bp-small) {
    flex-wrap: wrap;
    height: auto;
  }

  .form-label {
    @include bp(max-width, $bp-small) {
      width: 100%;
      margin: 0;
    }
  }

  > div {
    margin-right: 4rem;
  }

  .comafranc-checkbox-container {

    @include bp(max-width, $bp-small) {
      margin-top: 2rem;
      display: flex;
      align-items: center;
    }
  }
}
.fieldset-flex {
  display: flex;
  flex-wrap: wrap;
}
.catalogrequest-form {
  .fieldset {
    @include bp(max-width, $bp-small) {
      margin-bottom: 0;
    }
  }

  .form-hint {
    @include bp(max-width, $bp-large) {
      margin-bottom: 0;
    }
  }
}

.form.appointment {

  border-left: 1px solid $c-mercury;
  border-right: 1px solid $c-mercury;
  border-top: 1px solid $c-mercury;
  @include space('padding', 'top');

  @include bp(max-width, $bp-large) {
    border: none;

    .catalogrequest-form-container .catalogrequest-form .fieldset {
      width:100%;

      &:last-child {
        margin-bottom: 0;
      }
    }
    .actions-toolbar {
      @include space('margin', 'top');
    }
  }

  @include bp(max-width, $bp-small) {
    padding-top: 0;

    .field {
      width:100%;
    }
  }

  .field {
    &.no-label {
      margin-bottom: 0;
      padding-top: 1.6rem;

      @include bp(max-width, $bp-small) {
        width: 48%;
        display: inline-block;
        vertical-align: top;
      }

      &:before {
        padding: 0;
      }
    }

    &.zip_code {
      @include bp(max-width, $bp-small) {
        margin-bottom: 0;
      }
    }
  }

  .flexbox {
    justify-content: space-between;

    @include bp(max-width, $bp-small) {
      flex-direction: column;
      justify-content:flex-start;
    }
  }

  .catalogrequest-form-container {
    border: none;

    @include bp(max-width, $bp-small) {
      padding-top: 4rem;
    }
  }
}

.form .field.wanted-hour {

  @include bp(max-width, $bp-small) {
    width: 50%;
    display: inline-block;
    vertical-align: top;
    text-align: right;
  }

  select {
    margin-right: 1rem;
    height: 48px;
    text-align: center;
    text-align-last: center;
    border-radius: 50px;

    @include bp(max-width, $bp-small) {
      margin-right: 0;
      height:auto;
      width: 100%;
    }

    &:last-child {
      margin-left: 1rem;

      @include bp(max-width, $bp-medium) {
        margin-left: 0;
      }
    }
  }
}
.form .field.wanted-date {
  position: relative;

  @include bp(max-width, $bp-small) {
    width: 50%;
    display: inline-block;
    vertical-align: top;
  }

  svg {
    right: 20px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}
#wanted_date {
  &:focus {
    + svg {
      path {
        fill: $c-congress-blue;
      }
    }
  }
}
.header-only {
  display: none;
}
.appointment-summary {
  display: none;
  @include bp(min-width, $bp-small) {
    @include bp(max-width, $bp-large) {
      display: block;
      background: $c-concrete;
      text-align: center;
      @include space('padding', 'left');
      @include space('padding', 'right');
      @include space('padding', 'top');
      @include space('padding', 'bottom');
      width: 100%;
    }
  }

  > div {
    &:last-child {
      @include bp(min-width, $bp-small) {
        @include bp(max-width, $bp-large) {
          margin-top: 1rem;
        }
      }
    }
  }
}
.appointment-title-container {
  text-align: left;
  border-bottom: 1px solid $c-kashmir-blue;
  padding-bottom: 3.5rem;

  .appointment-title {
    font-weight: $bold-font-weight;
    @include font-size(2.6);
  }
}
