.cms-noroute-index {
  .page-title-wrapper {
    text-align: center;
  }
  .column.main{
    width:100%;
  }
}
.fullsize-page {
  .fullsize-page-seo {
    display: flex;
    border: 1px solid $c-mercury;
    @include space('padding', 'right');
    @include space('padding', 'left');
    align-items: center;
    min-height: 127px;

    @include bp(max-width, $bp-small) {
      min-height: 0;
    }

    h1 {
      flex: 1;
      font-weight: $bold-font-weight;
      @include font-size(1.8);
      color: $c-mine-shaft;
      margin: 0;

      @include bp(max-width, $bp-large) {
        margin-bottom: 2.5rem;
      }

      @include bp(max-width, $bp-small) {
        @include font-size(1.2);
      }
    }

    .fullsize-page-description {
      flex: 2;
      color: $c-gray;

      @include bp(max-width, $bp-small) {
        line-height:16px;
      }
    }
  }
}
.noroute-container {
  .noroute-content {
    position: relative;

    img {
      display: block;
    }

    .noroute-text {
      text-align: center;
      color: $c-white;
      font-weight: $light-font-weight;
      @include font-size(2.5);
      transform: translate(-50%, -50%);
      position: absolute;
      left: 50%;
      top: 50%;

      div {
        margin-bottom: 2.5rem;
        font-weight: $light-font-weight;
      }
    }
    @include bp(max-width, $bp-small) {
      overflow: hidden;
      .noroute-text {
        width:80%;
      }
      img {
        width:1445px;
        max-width: 1445px;
      }
    }
  }
}
.cms-noroute-index {
  @include bp(max-width, $bp-small) {
   h1 {
     text-align: center;
   }
  }
}