.page-layout-2columns-left .product.media {
  width: 100%;
}
.container-lien-aubade{
  @include bp(max-width, $bp-small) {
    text-align: center;
  }
  .see-products{
    position: absolute;
    right: 36px;
    top: 54px;
    z-index: 10;
    @include bp(max-width, $bp-medium) {
      position:static;
      margin-top:1rem;
      margin-left: 4.5rem;
    }
    @include bp(max-width, $bp-small) {
      position:static;
      margin-top:26px;
      margin-left: 0;
    }
  }
}
.page-layout-2columns-left .product-info-main {
  width: 100%;
  float: none;
  margin-top: 3.6rem;

  @include bp(max-width, $bp-small) {
    margin-top: 0;
  }
}
.catalog-product-view {
  .columns {
    @include bp(max-width, $bp-small) {
      border: none;
    }
  }

  .page-title {
    @include bp(max-width, $bp-small) {
      display: none;
    }
  }
}
.product-top {
  display: flex;
  justify-content: space-between;
  padding-top: 0;
  padding-bottom: 0;
  @include space('padding', 'left');
  @include space('padding', 'right');

  @include bp(max-width, $bp-large) {
    @include space('padding', 'bottom');
  }

  @include bp(max-width, $bp-small) {
    align-items: center;
    margin-top: 2.5rem;
  }

  .product-name-brand {

    .product-name {
      font-weight: $semibold-font-weight;
      @include font-size(2.6);
      color: $c-mine-shaft;

      @include bp(max-width, $bp-small) {
        @include font-size(1.4);
      }
    }
    .product-brand {
      color: $c-gray;
      font-weight: $light-font-weight;
    }
  }

  .overlay {
    display: none;
    background: $c-black;
    z-index: 5;
  }

  .cross-close {
    @include bp(max-width, $bp-large) {
      text-align: right;
    }

    svg {
      @include bp(max-width, $bp-large) {
        width: 10px;
        height: 10px;
      }
      polygon {
        @include bp(max-width, $bp-large) {
          fill: $c-gray;
        }
      }
    }
  }

  .social-links {
    margin: 1rem 2rem 0 0;

    @include bp(max-width, $bp-large) {
      opacity:1;
      transition: 1s all;
      width: 90%;
      background: white;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 10;
      padding: 2.5rem;
      margin: 0;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
    }

    li {
      margin-bottom: 0;
      display: inline-block;

      @include bp(max-width, $bp-large) {
        padding-bottom: 1rem;
        width: 100%;
        border-bottom: 1px solid $c-alto;
        margin-top: 1rem;
      }

      &:last-child {
        @include bp(max-width, $bp-large) {
          border-bottom: none;
        }
      }

      a {
        @include bp(max-width, $bp-large) {
          display: flex;
          align-items: center;
        }
      }
    }
    svg {
      width: 40px;
      path {
        fill: $c-gray;
      }
    }

    span {
      @include bp(max-width, $bp-large) {
        text-transform: uppercase;
        color: $c-gray;
        margin-left: 1.3rem;
      }
    }

    .hidden-md {
      @include bp(max-width, $bp-medium) {
        display: none;
      }
    }
  }
  .product-find-store {
    display: flex;
    align-items: center;
    .comafranc-button {
      box-shadow: 3px 2px 20px 0 $c-congress-blue-light;

      @include bp(max-width, $bp-medium) {
        width:127px;
        box-sizing: border-box;
        padding:1rem;
        text-align: center;
        border-radius: 80px;
      }

      @include bp(max-width, $bp-small) {
        @include font-size(1);
        border-radius: 15px;
        width: 90px;
      }
    }
  }
}
.product-description {
  letter-spacing: 0.06rem;
  color: $c-gray;
  padding-top: 0;
  padding-bottom: 0;
  @include space('padding', 'left');
  @include space('padding', 'right');
  position: relative;
  margin-bottom: 5rem;

  @include bp(max-width, $bp-small) {
    line-height: 16px;
    margin-bottom: 2.5rem;
  }

  .h1 {
    @include font-size(1.6);
    font-weight: $medium-font-weight;
    color: $c-mine-shaft;

    @include bp(max-width, $bp-small) {
      @include font-size(1);
      margin-bottom: 1.5rem;
      @include space('margin', 'top');
    }
  }

  .product-attributes {
    transform: translate(0,-50%);
    position: absolute;
    right: 10%;
    top: 0;

    svg {
      width: 46px;
    }
  }

  ul {
    list-style: disc;
  }
}
.gallery-placeholder {
  .fotorama__nav-wrap {
    position: absolute;
    bottom: 8%;
    left: 0;
  }
  .fotorama__active {
    .fotorama__dot {
      opacity: 1;
      background-color: $c-white;
      border-color: $c-white;
    }
  }

  .fotorama__dot {
    background-color: $c-white;
    border-color: $c-white;
    opacity: 0.4;
    width: 6px;
    height: 6px;

    @include bp(max-width, $bp-small) {
      width: 4px;
      height: 4px;
    }
  }

  .fotorama__arr {
    background: none;

    &:hover {
      background: none;
    }
  }

  .fotorama__arr--prev {
    .fotorama__arr__arr {
      @include bp(max-width, $bp-large) {
        background: url('../images/left-arrow-without-dash.svg') no-repeat center center transparent;
        background-size: 8px 14px;
      }
    }
  }

  .fotorama__arr--next {
    .fotorama__arr__arr {
      @include bp(max-width, $bp-large) {
        background: url('../images/right-arrow-without-dash.svg') no-repeat center center transparent;
        background-size: 8px 14px;
      }
    }
  }

  .fotorama__wrap .fotorama__arr {
    @include bp(max-width, $bp-xlarge) {
      opacity: 1;
      display: block !important;
    }
  }
  .fotorama__wrap--css3.fotorama__wrap--no-controls.fotorama__wrap--slide.fotorama__wrap--toggle-arrows .fotorama__arr--prev:not(:focus),
  .fotorama__wrap--css3.fotorama__wrap--no-controls.fotorama__wrap--slide.fotorama__wrap--toggle-arrows .fotorama__arr--next:not(:focus) {
    @include bp(max-width, $bp-xlarge) {
      transform: translate3d(0, 0, 0);
    }
  }
}

.product.media {
  float: none;

  @include bp(max-width, $bp-large) {
    margin-bottom: 5rem;
  }
}
.show-social-links {
  @include bp(max-width, $bp-medium) {
    height: 30px;
    margin:0 20px;
  }
}
.catalog-product-view {

  .page-main {
    @include bp(max-width, $bp-large) {
      margin-top: 0;
    }
  }

  @include bp(max-width, $bp-medium) {
    .product-info-main {
      order:1;
    }
    .product.media {
      order:2;
    }
    .product-description {
      order:3;
    }
    .products-grid .product-items .product-item {
      width:50%;
    }
    .columns {
      display: flex;
      flex-wrap: wrap;
      .sidebar {
        width:255px;
        margin-right:1px;
        order:1;
        flex:initial;
      }
      .column.main {
        order:2;
        flex:1;
      }
      .subcategories-container li {
        display: block;
        @include space('margin', 'bottom');

        .subcategory-text-container {
          background: transparent;
          margin-top:-24px;
        }
        .subcategory-text {
          position: relative;
          transform: none;
          margin:0 13px;
          left:0;
          box-shadow: 0 3px 20px 7px $c-gray;
        }
      }
    }
  }
  @include bp(max-width, 646px) {
    .columns .sidebar {
      width:240px;
    }
  }
    @include bp(max-width, $bp-small) {
    .sidebar {
      display: none;
    }
    .subcategory-text > div:not(.subcategory-name) {
      display: none;
    }
    .columns {
      .subcategories-container {
        img {
          max-width: 430px;
        }
        li {
          background-color: $c-mercury;
          position: relative;
          margin-bottom:1px;
        }
        li .subcategory-text-container {
          position: absolute;
          right:50px;
          top:0;
          bottom: 0;
          margin:auto auto;
          display: inline-table;

          .subcategory-text {
            width:356px;
            padding: 0;
            margin: 0;
            display: inline-block;
            text-align: center;
            box-sizing: border-box;
            @include space('padding', 'left');
            @include space('padding', 'right');
            @include space('padding', 'top');
            @include space('padding', 'bottom');
            box-shadow: 0 7px 45px -3px $c-gray;

            a {
              margin-bottom:0;

            }
          }
        }
      }
    }
    .page-title {
      text-align: center;
    }
  }
}
@include bp(max-width, $bp-small) {
  .page-products .columns .subcategories-container img {
    max-width: 68%;
  }
  .page-products .page-title-wrapper {
      margin-top: 62px;
  }
  .page-products .columns .subcategories-container li{
    background-color: white;
  }
  .page-products .columns .subcategories-container li .subcategory-text-container {
    text-align: center;
    display: block;
    right: 0;
    bottom:57%;
  }
  .subcategory-name a{
    padding-top: 50px;
    padding-bottom: 50px;
  }

}
.page-products{
  @include bp(min-width, $bp-medium) {
    .page-title-wrapper {
      display: flex;
      justify-content: space-between;
    }
    .page-title {
      width: 80%;
    }
  }
}
