.slick-dots {
  display: block;
  width: 100%;
  padding: 0 0 2.5rem 0;
  list-style: none;
  text-align: center;
  margin: 1rem 0 0;

  @include bp(max-width, $bp-medium) {
    @include bp(min-width, $bp-small){
    margin-top: 2.5rem;
    }
  }

  li  {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0;
    padding: 0;
    cursor: pointer;

    &.slick-active button:before {
      opacity: .75;
      color: black;
    }



    button {
      font-size: 0;
      line-height: 0;
      display: block;
      width: 20px;
      height: 20px;
      padding: 5px;
      cursor: pointer;
      color: transparent;
      border: 0;
      outline: none;
      background: transparent;

      &:before {
        @include font-size(4);
        line-height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        content: '\00b7';
        text-align: center;
        opacity: .25;
        color: black;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        @include bp(max-width, $bp-small) {
          @include font-size(3.2);
        }
      }


      &:active {
        box-shadow: none;
      }
    }
  }
}
.slide-bottom {
  display: table;
  background: $c-concrete;
  width: 100%;
  height:103px;

  .slide-nb {
    padding: 0 1.8rem;
    background: $c-black;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    color: $c-white;
    font-weight: $light-font-weight;
  }

  .slide-caption-container {
    padding: 3rem 0;
    display: flex;
    align-items: center;

    @include font-size(1.8);
    color: $c-congress-blue-light;

    @include bp(max-width, $bp-medium) {
      padding: 3rem 0;
    }

    @include bp(max-width, $bp-small) {
      @include font-size(1.3);
    }
    .slide-control{
      svg {
        path {
          fill: $c-congress-blue-light ;
        }
      }
    }

    .slide-caption {
      align-items: center;
      display: flex;
      width: 50%;

      &.slide-caption-right {
        justify-content: flex-end;

        @include bp(max-width, $bp-small) {
          margin-top: 2.2rem;
          justify-content: center;
          margin-bottom: 0.5rem;
        }
      }
    }

  }
}
.home-services-container{
  .services-menu{
    width:100%;
  }
  .homepage-services{
    width:20%;
  }
}
