@mixin font-size($sizeValue: 1.6) {
  font-size: ($sizeValue * 10) + px;
  font-size: $sizeValue + rem;
}

@-moz-document url-prefix() {
  * {
    min-height: 0;
    min-width: 0;
  }
}

body {
  color: $c-dusty-grey;
  font-family: Hind, Helvetica, Arial, sans-serif;
  font-weight: $light-font-weight;
  @include font-size(1.4);
  letter-spacing: 0.1em;

  @include bp(max-width, $bp-small) {
    @include font-size(1);
  }
}
@include bp(min-width, $bp-small) {
  @include bp(max-width, $bp-medium) {
    .page-title-wrapper{
      margin-top: 114px !important;
      margin-bottom: 5rem !important;
    }
    .page-title{
      margin-bottom: 0 !important;
    }
  }
}
@include bp(max-width, $bp-small) {
  #maincontent{
    margin-top: 40px;
  }
}

.back-link {
  display: inline-block;
  color: $c-mine-shaft;
  font-weight: $bold-font-weight;
  @include font-size(1.6);
  float: right;
  line-height: 50px;

  @include bp(max-width, $bp-medium) {
    line-height: 3.4rem;
  }

  @include bp(max-width, $bp-small) {
    @include font-size(0.9);
    background: $c-concrete;
    width: 100%;
    position: absolute;
    top: 40px;
    text-align: left;
    left: 0;
    box-sizing: border-box;
    padding: 0 0 0 2.5rem;
  }

  svg {
    @include bp(max-width, $bp-small) {
      display: none;
    }
  }
}
.columns .column.main {
  padding-bottom: 0;
}
.comafranc-button {
  box-shadow: 3px 2px 20px 0 #d9d9d9;
  display: inline-block;
  vertical-align: top;
  font-weight:$regular-font-weight;
  font-family: Hind, Helvetica, Arial, sans-serif;
  letter-spacing: 0.14rem;
  background: $c-white;
  @include font-size(1.4);
  color: $c-congress-blue-dark;
  padding: 1.4rem 3rem 1.2rem;
  border-radius: 20px;

  @include bp(max-width, $bp-small) {
    @include font-size(1);
    padding: 1.1rem 2.5rem 1rem;
    border-radius: 35px;
  }

  &.darkblue{
    background-color: $c-congress-blue-light;
    color:$c-white;
    &:hover {
      color: $c-congress-blue;
      background: $c-white;
      text-decoration: none;
    }
  }
  &.payment-link {
    background-color: $c-white;
    color:$c-congress-blue-light;
    text-transform: uppercase;
    text-align: center;
    box-shadow: none;
    border-radius: 30px;
    padding: 8px 40px;
    border: 1px solid $c-white;

    &:hover {
      color: $c-white;
      background: $c-congress-blue-light;
    }

    span {
      display: block;
      font-weight: 600;
    }
  }
  &:hover {
    color: $c-white;
    background: $c-congress-blue;
    text-decoration: none;
  }

  &.comafranc-blue-button {
    background: $c-curious-blue;
    border: 1px solid $c-curious-blue;
    color: $c-white;
    padding-top: 0.6rem;
    padding-bottom: 0.2rem;

    span {
      margin-left: 5px;
      padding-top: 10px;
      vertical-align: top;
      display: inline-block;
    }

    &:hover {
      background: $c-denim;
    }
  }

  &.comafranc-dark-blue-button {
    border: 1px solid $c-white;
    box-shadow: none;
    color: $c-congress-blue-light;

    &:hover {
      background: $c-prussian-blue;
      color: $c-white;
    }
  }
}
.comafranc-checkbox {
  width: 15px;
  height: 15px;
  position: relative;
  background: $c-gray;
  display: inline-block;
  vertical-align: top;

  @include bp(max-width, $bp-small) {
    margin-right: 1rem;
    float: left;
  }

  label {
    width: 13px;
    height: 13px;
    position: absolute;
    top: 1px;
    left: 1px;
    cursor: pointer;
    background: $c-white;

    &:after {
      content: '';
      width: 9px;
      height: 9px;
      position: absolute;
      top: 2px;
      left: 2px;
      background: $c-mine-shaft;
      opacity: 0;
    }
    &:hover::after,
    &.mouseenter:after{
      opacity: 0.3;
    }
  }
  input[type=checkbox],
  input[type=radio] {
    visibility: hidden;
    &:checked + label:after {
      opacity: 1;
    }
  }
}
.comafranc-checkbox-label {
  margin-left: 1.7rem;
  cursor: pointer;
  @include bp(max-width, $bp-small) {
    margin-left: 0;
  }

  &:hover, &.active {
    color: $c-mine-shaft;
  }
}
.form-label {
  font-weight: $bold-font-weight;
  color: $c-mine-shaft;
}

ul {
  list-style: none;
}
input:not([disabled]):focus {
  box-shadow: none;
}
.column:not(.sidebar-main) form .actions-toolbar, .column:not(.sidebar-additional) form .actions-toolbar {
  margin-left: 0;
}
.action.primary {
  background: $c-concrete;
  border: none;
  color: $c-mine-shaft;
  font-weight: $medium-font-weight;
  border-radius: 0;

  &:hover {
    background: $c-congress-blue;
    border: none;
  }
}

.form {
  .field {
    position:relative;
    margin-bottom:2.6rem;
    width: 48%;

    @include bp(max-width, $bp-small) {
      width:100%;
      margin-bottom: 1.8rem;
    }

    &.no-label {
      margin-bottom: 4rem;
      @include bp(max-width, $bp-medium) {
        margin-bottom: 4.9rem;
      }
      @include bp(max-width, $bp-small) {
        margin-bottom: 2.8rem;
      }
    }

    &.field-wide {
      width: 100%;
    }

    &.no-label:before, &.no-label:after {
      float: none;
      content: '';
      width:0;
      padding: 0;
    }

    input, textarea {
      display:block;
      border:1px solid $c-alto;
      border-radius: 25px;
      padding-left: 3rem;
    }
    input {
      height: 48px;

      @include bp(max-width, $bp-small) {
        height: 35px;
      }
    }
    textarea {
      height: 156px;
    }
    input:focus, textarea:focus { outline:none; }

    label {
      color:$c-gray;
      position:absolute;
      pointer-events:none;
      left: 33px;
      top:0;
      transition:0.2s ease all;
      -moz-transition:0.2s ease all;
      -webkit-transition:0.2s ease all;
      transform: translateY(80%);

      &.textarea-label {
        top: 10%;
        transform: translate(0, 0);
      }
    }

    /* active state */
    input:focus ~ label, input.input-filled ~ label,
    textarea:focus ~ label, textarea.input-filled ~ label {
      top:-31px;
      @include font-size(1.4);
      color: $c-congress-blue;

      @include bp(max-width, $bp-small) {
        @include font-size(1);
      }
    }

    textarea:focus ~ label, textarea.input-filled ~ label {
      top:-19px;
    }

    /* BOTTOM BARS ================================= */
    .bar 	{ position:relative; display:block;  }
    .bar:before, .bar:after 	{
      content:'';
      height:2px;
      width:0;
      bottom:1px;
      position:absolute;
      background: $c-congress-blue;
      transition:0.2s ease all;
      -moz-transition:0.2s ease all;
      -webkit-transition:0.2s ease all;
    }
    .bar:before {
      left:50%;
    }
    .bar:after {
      right:50%;
    }

    /* active state */
    input:focus ~ .bar:before, input:focus ~ .bar:after,
    textarea:focus ~ .bar:before, textarea:focus ~ .bar:after {
      width:45%;
    }

    div.mage-error[generated] {
      display: none !important;
    }
  }
  .legend {
    font-weight: $bold-font-weight;
    @include font-size(1.8);
    color: $c-mine-shaft;
    width: 100%;
    margin-left:0;
    float: none;
    margin-bottom: 0.3rem;
  }

  .actions-toolbar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;

    button {
      width: 100%;
      height: 70px;

      @include bp(max-width, $bp-small) {
        @include font-size(1.1);
      }
    }
  }
}
.form-hint {
  @include font-size(1.3);
  color: $c-gray;

  @include bp(max-width, $bp-small) {
    @include font-size(1);
  }
}
.flexbox {
  display: flex;
}
.comafranc-select-small {
  width: 30%;

  @include bp(max-width, $bp-small) {
    width: 50%;
  }
}
.clearfix {
  &:after {
    content: ".";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }
}
.social-links-container {
  background: $c-concrete;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 4rem 0;

  .social-links {
    margin: 1rem 0 0;
    padding-left:1.8rem;

    @include bp(max-width, $bp-small) {
      padding-left:0;
    }

    svg {
      width: 34px;

      @include bp(max-width, $bp-small) {
        width: 25px;
      }
      path {
        fill: $c-gray;
      }
    }

    li {
      display: inline-block;
      margin:0 5px;
    }
  }
}
.field {
  textarea {
    &.mage-error {
      border-color: $c-red;
    }
  }
  input {
    &.mage-error {
      border-color: $c-red;
    }
  }
}
