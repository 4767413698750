.findapro-index-index {
  .page-title-wrapper {
    text-align: center;
  }
}
.findapro-container {
  .findapro-section {
    display: table;
    letter-spacing: 0.06rem;
    width: 100%;

    @include bp(max-width, $bp-large) {
      display: flex;
      flex-direction: column;
    }

    &:first-of-type {
      .findapro-column.column-left {
        border-top: 1px solid $c-mercury;

        @include bp(max-width, $bp-small) {
          @include space('padding', 'left');
          @include space('padding', 'right');
        }
      }
    }

    .findapro-column {
      display: table-cell;
      vertical-align: top;

      .comafranc-button {
        letter-spacing: 0.06rem;
      }

      &.column-left {
        @include space('padding', 'left');
        @include space('padding', 'right');
        padding-top: 3rem;
        padding-bottom: 3rem;
        border-left: 1px solid $c-mercury;

        @include bp(max-width, $bp-small) {
          padding: 0 0 1.5rem 0;
        }
      }

      &.column-right {
        width: 965px;

        @include bp(max-width, $bp-xlarge) {
          width:100%;
          display: inline-block;
        }

        img {
          display: block;

          @include bp(max-width, $bp-xlarge) {
            width:100%;
          }
        }
      }
    }

    .h2 {
      @include font-size(1.8);

      @include bp(max-width, $bp-small) {
        @include font-size(1.2);
      }
    }

    .findapro-description {
      color: $c-gray;
      margin-top:1rem;
      font-weight: $light-font-weight;

      @include bp(max-width, $bp-small) {
        line-height:16px;
      }
    }

    .comafranc-button {
      box-shadow: 3px 2px 20px 0 $c-alto;
      margin-top:4rem;

      @include bp(max-width, $bp-small) {
        @include font-size(1);
        padding-left: 2.5rem;
        padding-right: 2.5rem;
      }
    }

    .findapro-jobs-list {
      background: $c-mine-shaft;
      color: $c-gray;

      @include bp(max-width, $bp-xlarge) {
        width:256px;
        box-sizing: border-box;
        padding:15px 40px;
      }

      @include bp(max-width, $bp-small) {
        padding-left: 0;
        padding-right: 0;
        width:100%;
        display: inline-block;
        @include font-size(0.9);
      }

      input:not([disabled]):focus {
        box-shadow: none;
      }

      .findapro-title {
        color: $c-white;
        text-transform: uppercase;
        font-weight: $bold-font-weight;
        @include font-size(1.8);
        margin-top:1.8rem;

        @include bp(max-width, $bp-small) {
          padding-left: 5rem;
          padding-right: 5rem;
          @include font-size(1.2);
        }

        .settings-icon {
          @include bp(max-width, $bp-small) {
            float: right;
          }

          svg {
            @include bp(max-width, $bp-small) {
              width: 11px;
            }
          }

          .close {
            display: none;
          }
        }
      }

      .findapro-subtitle {
        font-weight:$semibold-font-weight;
        color: $c-white;
        margin-top:4rem;
        margin-bottom:2.5rem;

        @include bp(max-width, $bp-small) {
          display: none;
          padding-left: 2.5rem;
          padding-right: 2.5rem;
          margin-bottom: 2.75rem;
          @include font-size(1.2);
        }
      }

      ul {
        list-style: none;

        @include bp(max-width, $bp-xlarge) {
          padding-left:0;
        }

        @include bp(max-width, $bp-small) {
          display: none;
          padding-left: 2.5rem;
          padding-right: 2.5rem;
        }

        li {
          @include bp(max-width, $bp-large) {
            display: flex;
          }

          @include bp(max-width, $bp-small) {
            width: 49%;
            display: inline-block;
            vertical-align: top;
            margin-bottom: 3.25rem;
          }
        }
      }
    }

    .findapro-pros-number {
      background: $c-prussian-blue;
      color: $c-white;
      @include font-size(2.6);
      padding:4rem;
      letter-spacing: 0.07rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include bp(max-width, $bp-large) {
        @include space('padding', 'top');
        @include space('padding', 'bottom');
      }

      @include bp(max-width, $bp-small) {
        @include font-size(1.8);
      }

      .findapro-change-button {
        background: $c-white;
        font-weight: $semibold-font-weight;
        @include font-size(1.4);
        color: $c-congress-blue;
        border-radius: 5px;
        justify-content: center;
        display: flex;
        align-items: center;
        width: 138px;
        height: 46px;
        box-sizing: border-box;
        letter-spacing: 0.02rem;
        cursor: pointer;

        @include bp(max-width, $bp-small) {
          @include font-size(1);
        }

        &.button-map {
          display: none;

          svg {
            margin-right:1rem;
          }
        }

        svg {
          margin-right:2rem;
        }

        span {
          margin-top:3px;
        }
      }
    }

    .findapro-pros-list {

      #pros-map {
        height:744px;
        max-height:744px;

        .ga-button {
          box-shadow: none;
          line-height: 16px;
          background-position: 0 50%;
          padding-left: 4.5rem;
          height: auto;
        }

        .shop-map-tooltip-column {
          line-height: 22px;

          @include bp(max-width, $bp-small) {
            line-height: 16px;
          }
        }
      }

      #pros-list {
        list-style: none;
        padding-left: 0;
        max-height:744px;
        overflow-y: scroll;
        color: $c-mine-shaft;
        display: none;

        li {
          display: flex;
          width: 100%;
          padding-left:4.3rem;
          height: 169px;
          box-sizing: border-box;
          margin-bottom: 0;

          @include bp(max-width, $bp-large) {
            @include space('padding', 'left');
            display: flex;
            height: auto;
          }

          .pro-list-contact-info {
            @include space('padding', 'right');

            @include bp(max-width, $bp-large) {
              margin-top: 1.75rem;
            }
          }

          .pro-list-info-container {
            width: 75%;

            @include bp(max-width, $bp-large) {
              margin-top: 2.5rem;
              margin-bottom: 2.25rem;
            }

            @include bp(max-width, $bp-small) {
              display: flex;
              flex-direction: column;
              flex: 2;
            }

            > div {
              @include bp(max-width, $bp-large) {
                width: 50%;
              }

              @include bp(max-width, $bp-small) {
                width: 100%;
              }
            }

            .pro-list-jobs-contact {
              @include bp(min-width, $bp-large) {
                display: flex;
                width: 66.666666%;
                justify-content: space-between;
                align-items: center;
              }

              > div {
                @include bp(min-width, $bp-large) {
                  width: 50%;
                }
              }
            }
          }

          .pro-list-button-container {
            @include bp(max-width, $bp-large) {
              align-self: center;
              flex: 1;
              justify-content: center;
              display: flex;
            }
          }


          &:nth-child(odd) {
            background-color: $c-white;
          }

          &:nth-child(even) {
            background-color: $c-concrete;
          }

          &.pro-odd {
            background-color: $c-concrete;
          }

          &.pro-even {
            background-color: $c-white;
          }

          .ga-button {
            @include bp(max-width, $bp-large) {
              background: none;
              box-sizing: initial;
              width: 39px;
              height: 36px;
              padding: 26px;
            }

            img {
              @include bp(max-width, $bp-large) {
                width:auto;
              }
            }
          }

          & > div {
            display: flex;
            width: 25%;
            justify-content: space-between;
            align-items: center;

            @include bp(max-width, $bp-large) {
              width: 75%;
            }
          }
        }

        .pro-name {
          font-weight: $semibold-font-weight;
          @include font-size(1.6);
          padding-right:1rem;
          margin: 0;

          @include bp(max-width, $bp-small) {
            @include font-size(1.3);
          }
        }

        .pro-address-line {
          color: $c-gray;
        }
      }
    }
  }

  .findapro-search-section {
    display: table;
    @include bp(max-width, $bp-small) {
      display: flex;
      flex-direction: column;
    }

    .column-left {
      @include bp(max-width, $bp-small) {
        order: 2;
      }
    }
    .column-right {
      @include bp(max-width, $bp-small) {
        order: 1;
      }
    }
  }

  @include bp(max-width, $bp-small) {
    .findapro-section .findapro-column.column-left {
      width:100%;
      border:none;
    }
    .findapro-section:nth-child(1) {
      display: block;
    }
  }
}
.ga-button {
  height: 70px;
  padding-right: 2rem;
  box-sizing: border-box;
  padding-left: 5.5rem;
  width: 193px;
  display: table-cell;
  background: url(../images/logos/mini-logo-ga.png) no-repeat 1.5rem 50% $c-white;
  vertical-align: middle;
  border-radius: 10px;
  box-shadow: 0 2px 10px 0 $c-alto;
  color: $c-congress-blue;
}
.validate-pros-list {
  border-top: 1px solid $c-tundora;
  width: 100%;
  color: $c-white;
  text-align: center;
  @include font-size(1.1);
  padding-top: 1.75rem;
  display: none;
  font-weight: $medium-font-weight;
}
