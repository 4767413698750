.comafranc-catalog-ourproducts-index {
  .page-title-wrapper {
    text-align: center;
    margin-top: 6.3rem;
  }
}
.catalog-category-view {
  .page-title-wrapper {
    @include bp(max-width, $bp-small) {
      text-align: center;
    }
  }
}
.slick-dots li.slick-active button:before {
  color:$c-congress-blue-light;
}

.ourproducts-container {
  .fullsize-page-seo {
    padding-top: 5.3rem;
    padding-bottom: 6.7rem;
  }
  a {
    color: $c-gray;
    display: flex;
    align-items: center;
    font-weight: $light-font-weight;

    &:hover {
      text-decoration: none;
      color: lighten($c-gray, 10%);
    }

    span {
      display: block;
      margin-top: 0.5rem;
      margin-left: 1rem;
    }
  }

  ul {
    padding:0;
    list-style: none;
  }

  .ourproducts-categories {
    border-left: 1px solid $c-mercury;
    border-right: 1px solid $c-mercury;
  }

  .main-category-title {
    display: block;
    font-weight: $bold-font-weight;
    @include font-size(1.8);
    color: $c-mine-shaft;
    margin-bottom:2rem;
    width: 100%;
  }

  .main-categories {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0;

    .categories-item {
      width: 25%;
      margin-bottom: 0;

      &.fullsize {
        width: 50%;
      }

      &.categories-names {
        padding: 6rem 6rem 0;
        box-sizing: border-box;

        &.without-title {
          padding-top: 10.5rem;
          border-top: 1px solid $c-concrete;
        }
      }

      img {
        display: block;
      }

      &:nth-child(7),
      &:nth-child(12),
      &:nth-child(13),
      &:nth-child(15) {
        background: $c-concrete;
      }
    }
  }

  .subcategories {
    li {
      margin-bottom: 0;
    }
  }
}

.sidebar-categories {
  ul {
    list-style: none;
    padding: 0;
  }

  .sidebar-category-description {
    background: $c-concrete;
    font-weight: $light-font-weight;
    @include space('padding', 'bottom');
    @include space('padding', 'top');
    @include space('padding', 'left');
    @include space('padding', 'right');

    .sidebar-category-name {
      font-weight: $bold-font-weight;
      color:$c-gray;
    }
    .sidebar-short-description{
      font-weight:$medium-font-weight;
      color:$c-sylver-chalice;
    }
  }

  .main-categories {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    > li {
      display: flex;
      margin: 0;
      flex-direction: column;

      &:nth-child(1) {
        background: #a0b9d0;
      }
      &:nth-child(2) {
        background: #80a2c0;
      }
      &:nth-child(3) {
        background: #608ab0;
      }
      &:nth-child(4) {
        background: #4073a1;
      }
      &:nth-child(5) {
        background: #205b91;
      }
      &:nth-child(6) {
        background: #004481;
      }
      &:nth-child(7) {
        background: #003c71;
      }
      &:nth-child(8) {
        background: #003361;
      }
      &:nth-child(9) {
        background: #002b51;
      }
      &:nth-child(10) {
        background: #002241;
      }
      &:nth-child(11) {
        background: #001a30;
      }
    }
    .main-category-title {
      @include font-size(1.6);
      height: 70px;
      box-sizing: border-box;
      color: $c-white;
      padding-left:3rem;
      padding-right:2.5rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:hover {
        text-decoration: none;
      }

      .arrow-bottom {
        display: none;

        svg {
          width: 12px;

          path {
            fill: $c-white;
          }
        }
      }

      &.active {
        .arrow-right {
          display: none;
        }
        .arrow-bottom {
          display: block;
        }
      }
    }
  }

  .subcategories {
    display: none;
    background: $c-white;
    padding-top: 4.5rem;
    padding-bottom:4rem;
    @include space('padding', 'left');

    li {
      padding-bottom:13px;
    }

    &.active {
      display: block;
    }
    a {
      color: $c-dusty-grey;
      font-weight: $medium-font-weight;

      &:hover, &.active {
        text-decoration: none;
        color: $c-black;
      }
    }
  }
}
.subcategories-container {
  ul {
    list-style: none;
    color: $c-gray;
    margin: 0 0 0 0.1rem;
    padding:0;

    li {
      display: flex;
      margin-bottom: 0.1rem;

      &:last-child {
        margin-bottom:0;
      }

      img {
        display: block;
      }
    }

    .subcategory-text-img {
      flex: 1;
    }
    .subcategory-text-container {
      background: $c-concrete;
      flex: 1;
      position: relative;

      @include bp(max-width, $bp-small) {
        width: 60%;
        right: 2.4rem !important;
        height:50%;
        background: $c-concrete;
      }
      .subcategory-text {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        background: $c-white;
        margin-right:2rem;
        padding-top:5.8rem;

        .aubade-link{
          @include font-size(1.2);
          font-weight: $semibold-font-weight;
          color: $c-mine-shaft;
          @include bp(max-width, $bp-small) {
            display:none;
          }
          @include bp(min-width, $bp-medium) {
            display:none;
          }
        }

        @include bp(min-width, $bp-xlarge) {
          height:245px;
          right:50px !important;
          width:588px;
        }
        @include space('padding', 'left');
        @include space('padding', 'right');
        @include space('padding', 'bottom');

        @include bp(max-width, $bp-xlarge) {
          position: relative;
          transform: none;
          margin:0 13px;
          box-shadow: 0 3px 20px 7px $c-gray;
        }

        @include bp(max-width, $bp-large) {
          @include space('padding', 'top');
        }

        @include bp(max-width, $bp-small) {
          padding: 0;
          margin: 0;
          text-align: center;
          box-sizing: border-box;
          box-shadow: 0 7px 45px -3px $c-gray;
          display:flex;
          justify-content: center;
          align-items: center;
        }

        &:hover {
          box-shadow: 3px 2px 20px 0 $c-alto;
        }
      }
    }

    .subcategory-name {
      a {
        @include font-size(3);
        color: $c-congress-blue-light;
        margin-bottom:1rem;
        padding:0;
        align-content: center;
        justify-content: center;
        @include bp(max-width, $bp-medium) {
          @include font-size(2.4);
        }
        @include bp(max-width, $bp-small) {
          @include font-size(1.4);
        }



        &:hover {
          text-decoration: none;
          color: lighten($c-congress-blue-light, 15%);
        }
      }
    }

    .subcategory-description {
      font-weight: $light-font-weight;
      margin-bottom:1.6rem;
      margin-top: 0.2rem;
      line-height: 22px;
      @include bp(max-width, $bp-medium) {
        @include font-size(1.4);
      }
    }
  }
}
.columns {
  .column.main {
    order:2;
    flex:3;
  }
}
.catalog-category-view, .catalog-product-view {
  .columns {
    border-top: 1px solid $c-mercury;
    border-left: 1px solid $c-mercury;
    border-right: 1px solid $c-mercury;
    display: flex;
    flex-wrap: wrap;

    .column.main {
      order:2;
      flex:3;
    }
    .sidebar {
      order:1;
      flex:1;
      background-color:$c-concrete ;

      @include bp(max-width, $bp-xlarge) {
        width:255px;
        margin-right:1px;
        order:1;
        flex:initial;
      }

      @include bp(max-width, $bp-small) {
        display: none;
      }
    }
  }
}
.category-description-titre{
  font-weight:$semibold-font-weight;
  font-size: 2.6rem;
  margin: 55px 0 0 60px;
  color:$c-black;
  @include bp(max-width, $bp-medium) {
    margin: 3rem 0 0 4.3rem;
  }
  @include bp(max-width, $bp-small) {
    display:none;
  }
}

.category-description {
  color: $c-gray;
  padding: 9rem 0 6rem 0;
  border-left: 1px solid $c-mercury;
  margin: 0;

  @include bp(max-width, $bp-medium) {
    padding:3.7rem 0;
  }

  >p{
    padding-right:5.5rem;
    padding-left:6rem;
    @include bp(max-width, $bp-medium) {
      padding-right:3.4rem;
      padding-left:3.4rem;
    }

    @include bp(max-width, $bp-small) {
      padding-right:2rem;
      padding-left:2rem;
    }


    img{
      padding-top:45px;
      padding-bottom: 45px;
      @include bp(max-width, $bp-medium) {
        padding-top:2.9rem;
        padding-bottom: 1rem;
      }
      @include bp(max-width, $bp-small) {
        padding:0;
      }
    }
  }

  @include bp(max-width, $bp-large) {
    border: none;

    @include bp(max-width, $bp-small) {
      padding:2rem 0;
      border-bottom:1px solid $c-mercury;
    }
  }

  .shop-pictures-container {
    border: none;
    margin-top: 3rem;

    .shop-picture-control {
      background: transparent;

      svg {
        path {
          fill: $c-congress-blue-light;
        }
      }
    }
  }

  h2{
    margin-left: 25%;
    padding-left: 25px;
    padding-right: 5.5rem;
    border-left:3px solid black;
    font-weight:$medium-font-weight;
    font-size:20px;
    color:$c-mine-shaft;
    margin-top: 5rem;
    margin-bottom: 5rem;

    @include bp(max-width, $bp-medium) {
      margin-left: 8rem;
      margin-top: 4rem;
      margin-bottom: 4rem;
    }
    @include bp(max-width, $bp-small) {
      @include font-size(1.2);
      margin-top: 2rem;
      margin-bottom: 2rem;
      padding-left: 14px;
      margin-left: 15%;
      padding-right: 2rem;
    }
  }

  h3 {
    font-weight: $semibold-font-weight;
    @include font-size(2.6);
    color: $c-mine-shaft;
    letter-spacing: 0.07rem;
    margin-bottom: 3.5rem;
  }

  h4 {
    @include font-size(2.4);
    color: $c-mine-shaft;
    font-weight: $regular-font-weight;
  }
}
.external-links-container {
  .h2 {
    @include space('padding', 'left');
    @include space('padding', 'top');
    @include space('padding', 'bottom');
  }

  ul {
    display: flex;
    padding: 0;
    flex-wrap: wrap;
    margin-bottom: 0;
    border-left: 1px solid $c-alto;

    li {
      margin-bottom: 0;
      width: calc(33.3333333% - 1px);
      border-right: 1px solid $c-alto;
      border-bottom: 1px solid $c-alto;

      &:nth-child(3n) {
        border-right: none;
      }
    }
  }
  .external-link-img {
    display: block;

    img {
      display: block;
      width: 100%;
    }
  }
  .external-link-name {
    display: block;
    @include font-size(1.6);
    padding: 3.7rem 0 3.5rem 2.2rem;
    font-weight: $semibold-font-weight;
    color: $c-mine-shaft;
  }
}
// Products list

.page-products {
  @include bp(max-width, $bp-medium) {
    @include bp(min-width, $bp-small) {
      .breadcrumbs{
        margin-top: 54px;
      }
    }
  }
  .page-title-wrapper {
    @include bp(max-width, $bp-medium) {
      @include bp(min-width, $bp-small) {
        margin-top: 4rem !important;
        margin-bottom: 4rem !important;
      }
    }
  }
  @include bp(max-width, $bp-large) {
    @include bp(min-width, $bp-small) {
      .sidebar-additional{
        width:33% !important;
      }
      .columns .column{
        width:64% !important;
      }

    }
  }

  .page-main {
    @include bp(max-width, $bp-medium) {
      @include bp(min-width, $bp-small) {
        margin-top:0;
      }
    }
  }
  .columns{
    @include bp(max-width, $bp-small) {
      border:none;
      border-top:1px solid $c-alto;
    }
  }
  .page-title-wrapper{
    @include bp(max-width, $bp-small) {
      margin-bottom:2rem;
    }
  }
  .products {
    margin: 0;
  }
  .products-grid {
    .product-items {
      display: flex;
      flex-wrap: wrap;
      border-left: 1px solid $c-alto;
      width: 100%;

      @include bp(max-width, $bp-large) {
        margin: 0;
      }

      .product-item-info {
        width: 100%;
        position: relative;

        .product-attributes {
          position: absolute;
          right: 25px;
          top: 15px;
          z-index:1;

          svg {
            width: 46px;
          }
        }
      }

      .product-item {
        width: 33.3333333%;
        margin: 0;
        border-right: 1px solid $c-alto;
        border-bottom: 1px solid $c-alto;

        @include bp(max-width, $bp-xlarge) {
          width: 50%;
        }

        @include bp(max-width, $bp-large) {
          padding-left: 0;
        }

        @include bp(max-width, $bp-small) {
          margin: 0;
          padding: 0;
        }

        &.no-bottom-border {
          border-bottom: none;
        }

        .product-item-photo {
          overflow: hidden;
          display: block;
        }

        .photo.image {
          display: block;
          width: 100%;
          transition: all .3s;

          &:hover {
            transform: scale(1.1);
          }
        }

        .product-item-details {
          padding-left: 2rem;
          padding-bottom: 2.5rem;
          padding-top: 2rem;
        }

        .product-item-link {
          font-weight: $semibold-font-weight;
          @include font-size(1.6);
          color: $c-mine-shaft;

          @include bp(max-width, $bp-small) {
            @include font-size(1.2);
          }
        }

        .product-brand {
          font-weight: $medium-font-weight;
          color: $c-gray;
        }
      }
    }
  }
  @include bp(max-width, $bp-xlarge) {
    .subcategories-container ul li img {
      width: 100%;
    }
    .columns {
      display: flex;
      flex-wrap: wrap;
      .column.main {
        order:2;
        flex:1;
      }
      .subcategories-container li {
        display: block;
        @include space('margin', 'bottom');

        .subcategory-text-container {
          background: transparent;
          margin-top:-24px;
        }
      }
    }
  }
  @include bp(max-width, $bp-small) {
    .subcategory-text > div:not(.subcategory-name) {
      display: none;
    }
    .columns {
      .subcategories-container {
        img {
          max-width: 430px;
        }
        li {
          background-color: $c-mercury;
          position: relative;
          margin-bottom:1px;
        }
        li .subcategory-text-container {
          position: absolute;
          right:50px;
          top:0;
          bottom: 0;
          margin:auto auto;
          display: inline-table;

          .subcategory-text {
            a {
              margin-bottom:0;
            }
          }
        }
      }
    }
    .page-title {
      text-align: center;
      @include font-size(1.5);

    }
  }
}
div.main{
  @include bp(min-width, $bp-small) {
    @include bp(max-width, $bp-medium2) {
      .category-description-footer{
        display:none;
      }
    }
  }
}
.page-bottom{
  @include bp(min-width, $bp-small) {
    @include bp(max-width, $bp-medium2) {
      border-top: 1px solid $c-alto;
      margin:0 !important;
      .category-description-footer{
        border:0;
        .category-bottom-text{
          padding-bottom: 1.5rem;
        }
        .cat-button{
          padding-bottom: 3.7rem;
        }
      }
    }
  }
  @include bp(min-width, $bp-medium2) {
    display:none;
  }
  @include bp(max-width, $bp-small) {
    display:none;
  }
}

.category-description-footer{
  border-top: 1px solid $c-mercury;
  border-left: 1px solid $c-mercury;

  @include bp(max-width, $bp-small) {
    width:56%;
    margin:0 auto;
    border-top:none;
    border-left:none;
  }

  .category-bottom-text {
     width: 100%;
     text-align: center;
     color: $c-gray;
     padding-top: 4.5rem;
     display: block;
     padding-bottom: 2.5rem;
     line-height: 24px;
     font-weight: $light-font-weight;

    @include bp(max-width, $bp-small) {
      padding-top: 2.3rem;
      padding-bottom: 1.1rem;
      color:$c-congress-blue-light !important;
      line-height: 15px;
    }

     &:hover {
       text-decoration: none;
     }
   }
  div.cat-button{
    width:100%;
    text-align: center;
    padding-bottom:50px ;
    @include bp(max-width, $bp-small) {
      padding-bottom: 2.5rem;
    }
  }

}
.page-products, .catalog-product-view {
  .page-title-wrapper {
    @include bp(min-width, $bp-medium) {
      margin-top: 4rem;
      margin-bottom: 3rem;
    }
  }
}

