.top-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 54px;
  letter-spacing: 0.08em;
  width: 100%;
  max-width: none;
  @include bp(max-width, $bp-small) {
    flex-direction: row-reverse;
    height:40px;
  }


  .comafranc-mini-logo {
    border-right: 1px solid $c-mercury;
    padding-right:20px;
    margin-left: 20px;
    height:100%;
    @include bp(max-width, $bp-medium) {
      margin-left: 10px;
    }

    a {
      display: flex;
      height: 100%;
      align-items: center;
    }
  }
  .ebat {
    height: 100%;
    font-weight: $semibold-font-weight;
    background-color: $c-midnight;

    .ebat-logo-right{
      width:100%;
    }
    .ebat-st{
      width:100%;
      text-transform: uppercase;
      font-size: 10px;
    }

    a {
      text-decoration: none;
      color: $c-white;
      padding: 0 20px;
      height: 100%;
      align-items: center;
      display: flex;



      &:hover {
        background: lighten($c-congress-blue-dark, 3%);
      }
    }
  }

  .menu-contact{
    height: 100%;
    background-color: $c-congress-blue-dark;

    a {
      color: $c-white;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 30px;
      font-size: 13px;

      &:hover {
        text-decoration: none;
        background: lighten($c-midnight-blue, 3%);
      }
    }
  }

  .ebat-icon a{
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  .menu-sale {
    height: 100%;
    background-color: $c-midnight-blue;

    svg {
      width:18px;
      padding-left: 30px;
      path {
        fill: $c-white;
      }
    }

    a {
      height: 100%;
      color: $c-white;
      display: flex;
      align-items: center;
      padding-right: 30px;
      padding-left: 5px;
      font-size: 13px;

      &:hover {
        text-decoration: none;
        background: lighten($c-midnight-blue, 3%);
      }
    }
  }

  .rdv-expo {
    background: $c-midnight;
    text-align: center;
    height: 100%;
    display: flex;
    align-items: center;

    .header-only {
      display: block;
    }

    .page-only {
      display: none;
    }

    .open-rdv {
      cursor: pointer;
    }

    .close-rdv {
      cursor: pointer;
      display: none;
      text-transform: uppercase;

      &.close-rdv-visible {
        display: flex;
        width: 93.74px;
        justify-content: space-between;
        padding-left: 40px;
        padding-right: 40px;
      }

      svg {
        width:15px;
      }
    }

    .form .field input:focus ~ label,
    .form .field input.input-filled ~ label,
    .form .field textarea:focus ~ label,
    .form .field textarea.input-filled ~ label {
      color: $c-white;
    }

    .catalogrequest-form {
      width: 100%;
      color: $c-white;

      .fieldset {
        margin-bottom: 0;
      }

      .field {
        input, select, textarea {
          color: $c-white;
          background: $c-regal-blue;
        }

        label {
          color: $c-white;
        }
      }
    }

    .appointment-expo-room {
      border: none;
      padding: 0;

      label {
        position: static;
        transform: none;
      }
    }

    .make-appointment {
      display: none;
      position: absolute;
      left: 0;
      background: $c-regal-blue;
      top: 54px;
      color: $c-white;
      width: 100%;
      padding-left: 12%;
      padding-right: 12%;
      @include space('padding', 'top');
      max-width: none;
      z-index: 10;

      .select-subject select {
        color: $c-white;
        background: $c-regal-blue;
      }

      .form.appointment {
        border: none;
      }

      .form-label {
        color: $c-white;

        &:not(.centered-label) {
          text-align: left;
        }
      }

      .wanted-hour {
        text-align: left;
      }

      .form-hint {
        color: $c-white;
        opacity: 0.5;
        text-align: left;
      }

      .action.primary {
        background: $c-regal-blue;
        color: $c-white;
        border-top: 1px solid $c-kashmir-blue;

        &:hover {
          background: $c-white;
          color: $c-regal-blue;
        }
      }
    }

    a {
      color: $c-white;
      padding: 0 15px;
      font-weight: $light-font-weight;

      &:hover {
        text-decoration: none;
      }
    }

    &:hover {
      background: lighten($c-midnight, 3%);
    }
  }

  .header-search {
    background: $c-black;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;

    a {
      display: flex;
      padding: 0 25px;
      height: 100%;
      align-items: center;
    }

    &:hover {
      background: lighten($c-black, 10%);
    }
  }

  .menu-links {
    flex: 1;
    height:100%;

    .show-product-menu-icon {
      display: none;
    }

    .list-menu, .list-menu-nochild {
      position: relative;
      text-decoration: none;

      .arrow-bg {
        width:13px;
        height: 13px;
        background: $c-white;
        position: absolute;
        left: 50%;
        bottom: -10px;
        transform: translateX(-50%);
        z-index: 200;
        display: none;

        @include bp(max-width, $bp-xlarge) {
          display: none !important;
        }
      }

      svg {
        width: 13px;
        height: auto;
      }

      &:hover {
        .arrow-bg {
          display: block;
        }
      }
    }
    ul {
      list-style: none;
      margin: 0;
      padding:0;
      li {
        display: inline;
        a {
          color: $c-gray;
          @include font-size(1.3);
          :hover {
            text-decoration: none;
          }
        }
      }
    }
    .close-menu {
      display: none!important;
    }

    ul.level0 {
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;


      li {
        height:100%;
        margin: 0;
        display: flex;

        &:hover{
          font-weight:$bold-font-weight;
        }

        @include bp(max-width, $bp-xlarge) {
          height: auto;
        }

        @include bp(min-width, $bp-xlarge) {
          &:hover {
            ul {
              display: flex;
              flex-wrap: wrap;
            }

            .list-menu .arrow-bg {
              display: block;
            }
          }
        }
      }
    }

    li.level0 {
      height:100%;
      display: flex;
      align-items: flex-start;
      margin: 0;

      &:hover {
        ul.level1 {
          display: flex;
        }

        > a {
          display: flex;
          height: 100%;
          color: $c-black;

          &:hover {
            text-decoration: none;
          }
        }
      }
      &.active-menu {
        > a {
          color: $c-black;
        }
      }
      > a {
        padding: 0 10px;
        height: 100%;
        display: flex;
        align-items: center;
        font-weight: $light-font-weight;
      }

    }

    ul.level1 {
      z-index:100;
      display: none;
      flex-wrap: wrap;
      position: absolute;
      background-color: white;
      width: 100%;
      left: 0;
      top: 54px;
      border-top:1px solid $c-alto;
      padding: 4rem 13rem;
      box-sizing: border-box;
    }
    li.level1 {
      display:flex;
      flex-direction:column;
      width:25%;
      justify-content: flex-start;
      margin-top:20px;
      margin-bottom:20px;
      box-sizing: border-box;
      padding-bottom: 40px;

      > a {
        @include font-size(1.3);
        font-weight:$bold-font-weight;
        color: $c-mine-shaft-dark;
        margin-bottom: 0.8rem;
        display: block;
      }

      ul.level2 {
        width:100%;
      }

      li.level2 {
        padding:0;
        margin:0 0 0.5rem 0;
        display: block;
        width:100%;
        height: auto;

        > a {
          @include font-size(1.3);
          color: $c-gray;
        }
      }
      li.level2 svg {
        display: none;
      }
    }



    .bottom-menu-small {
      display: none;

      @include bp(max-width, $bp-small) {
        position: absolute;
        display: block;
        width: 100%;
        bottom: 0;
      }
    }
  }
  .hidden-menu-large {
    display: none;
    border-right: 1px solid $c-mercury;
  }
  svg.svg-right-arrow {
    display: none;
  }
  @include bp(max-width, $bp-xlarge) {

    .hidden-menu-large {
      display: block;
      height:20px;

      .close {
        display: none;
        padding-right:3px;
      }
      &.active {
        .close {
          display: block;
        }
        .open {
          display: none;
        }
      }
    }
    .comafranc-mini-logo {
      border-right:none;
      padding-left: 7px;
      flex:5;
    }
    .hidden-menu-large {
      width:20px;
      display: flex;
      height:100%;
      align-items: center;
      justify-content: center;
      border-left:1px solid $c-mercury;
      @include bp(max-width, $bp-small) {
        padding:0 10px;
        border-right:0;
      }
      @include bp(max-width, $bp-xlarge) {
        @include bp(min-width, $bp-small) {
          border-left: 0;
          width:55px;
        }
      }
      &.active {
        background-color: $c-regal-blue;
      }
    }
    .menu-links {
      flex:1;
      height:100%;

      @include bp(max-width, $bp-xlarge) {
        height: calc(100% - 54px);
        width: 340px;
        top: 54px;
        display: none;
        position: fixed;
        left: 0;
        background-color: $c-regal-blue;
        z-index:10;
        overflow-y: auto;
      }

      @include bp(max-width, $bp-small) {
        top:40px;
      }

      .show-product-menu-icon {
        display: inline-block;
        margin-top:2px;
        svg path {
          fill: white;
        }
      }
      li.level0:hover ul.level1 {
        display: none;
      }


      ul.level0 {
        padding:3rem 40px 0;
        flex-direction: column;
        height:auto;
        overflow: auto;

        > li {
          padding:15px 0;
          border-bottom: 1px solid rgba(255,255,255,0.2);
          width:100%;
          box-sizing: border-box;
          justify-content: space-between;
          a {
            color: $c-gray;
            @include font-size(1.4);
            font-weight:$regular-font-weight;
          }

          &.level0 {
            > a {
              color: $c-white;
              @include font-size(1.8);
            }

            &.active {
              > a {
                color: $c-mine-shaft;
                padding-left: 0;
              }
            }
          }
          &.active {
            border-bottom: 1px solid #d7d7d7;
            .show-product-menu-icon {
              display: none;
            }
            > .close-menu {
              display: inline-block!important;
              svg {
                width:12px;
                polygon {
                  fill:black;
                }
              }
            }
          }
        }
        &.active {
          background-color: $c-concrete;
        }
      }
      li.level0 {
        padding-top:40px;
        &.active {
          padding-top:1.5rem;

          > a {
            color: $c-mine-shaft;
            @include font-size(1.8);

            .arrow-bg {
              display: none;
            }
          }
        }
        > a {
          color: white;
          text-decoration: none;
        }
      }
      ul.level1 {
        border-top:none;
        background-color: $c-concrete;
        box-sizing: border-box;
        top: 106px;
        padding: 0;
        &.active-top {
          top:0;
          background-color: white;
          height:100%;
          >li {
            display: none;
            &.active {
              display: block;
            }
          }
        }
        &.active {
          display: flex!important;
          height:100%;
          align-content: flex-start;
        }
        a {
          color: black;
          &:hover {
            text-decoration: none;
            color: black;
          }
        }
      }
      li.level1 {
        width:100%;
        background-color: $c-concrete;
        margin:0;
        padding: 17px 40px 5px;
        border-bottom: none;
        > a {
          border-bottom: 1px solid rgba(0,0,0,0.5);
          padding-bottom:15px;
          @include font-size(1.0);
        }
        &.active {
          border-right: 10px solid $c-concrete;
          background-color: white;
          padding-top:4.6rem;
          border-bottom:none;

          > a {
            @include font-size(1.8);
            color: $c-mine-shaft;
            display: inline-block;

            svg.svg-right-arrow {
              display: none;
            }
          }

          > .close-menu {
            float: right;
            display: inline-block!important;
            width:12px;
            svg {
              width:12px;
              polygon {
                fill:black;
              }
            }
          }
        }
        &.active ~ li {
          display: none;
        }
        svg {
          display: inline-block;
          vertical-align: middle;
          width:14px;
          margin-right:10px;
        }

        :not(.active) {
          .close-menu {
            display: none!important;
          }
        }
        > a {
          text-transform: none;
          text-decoration: none;
          padding-bottom: 10px;
          width: 100%;
          display: inline-block;
          @include font-size(1.0);
          border-bottom: none;

          &:first-child {
            width: auto;
            margin-bottom: 0.5rem;
          }
        }
      }
      ul.level2 {
        display: none;

        &.active {
          display: block;
          border-top: 1px solid $c-mercury;
          padding-top: 2.5rem;
        }
      }
      li.level2 {
        margin-bottom: 0;
        > a {
          display: block;
          padding: 14px 0;
        }
        svg {
          display: inline-block;
          width:14px;
          margin-right:10px;
        }
      }
    }
  }
  @include bp(max-width, $bp-small) {
    .menu-links {
      width:100%;
      background-color: $c-regal-blue;
      > .bottom-menu-small {
        > div {
          width:100%;
          margin-top:20px;
        }
        .ebat {
          a {
            height: 50px;
            text-align: center;
            display: flex;
            justify-content: center;
          }
        }
        .rdv-expo {
          margin-top: 0;
          display: flex;
          justify-content: center;
          padding: 1.75rem 0;
          br {
            display: none;
          }
          a {
            @include font-size(1.1);
          }
        }
      }
    }
    .comafranc-mini-logo {
      border-right: none;
      flex: 5;
      margin-left: 0;
    }
    &> .ebat, &> .menu-contact, &> .menu-sale {
      display: none;
    }
  }
}
.page-header {
  position: relative;
  width: 100%;
  .header-search-toolbar {
    position: absolute;
    width: 100%;
    top: 54px;
    right:0;
    margin: 0 auto;
    background-color: $c-black;
    display: flex;
    justify-content: space-between;
    vertical-align: middle;
    flex-wrap: wrap;
    padding: 20px 0;
    align-items: center;

    @include space('padding', 'left');
    @include space('padding', 'right');
  }
  .block-search {
    width: 80%;
    padding: 0 20px;

    @include bp(max-width, $bp-small) {
      padding: 0;
    }
  }
  .search-action {
    padding: 18px 27px 15px;
    border: 1px solid $c-white;
    color: $c-white;
    @include font-size(1.6);
    font-weight: $medium-font-weight;
    margin-right: 20px;
    float: right;
    display: inline-block;

    @include bp(max-width, $bp-small) {
      padding: 1.5rem 1.3rem;
      box-sizing: border-box;
      margin-right: 0;
      @include font-size(1);
    }

    &:hover {
      color: $c-black;
      background: $c-white;
      text-decoration: none;
    }
  }
  @include bp(max-width, $bp-xlarge) {
    position: fixed;
    background-color: white;
    top:0;
    left: 0;
    right: 0;
    margin:0 auto;
    z-index:100;
  }
}

.page-header .header.panel {
  padding: 0;
}
.breadcrumbs {
  background-color: $c-concrete;
  max-width: none;
  padding: 0.6rem 0;
  margin-bottom: 0;
  @include font-size(1.2);

  .items {
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    margin: 0;
    padding: 0 0 0 26px;
    color: $c-gray;

    li {
      list-style: none;
      margin: 0 3px;

      &:first-child {
        margin-left: 0;
      }
    }

    a {
      color: $c-gray;
    }
  }
}


@include bp(max-width, $bp-xlarge) {
  .breadcrumbs {
    .items {
      margin-left: 0;
    }
  }
}