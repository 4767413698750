.services-index-index{
  .page-title-wrapper{
    text-align: center;
  }

}

.service-description-container{
  display:flex;
  flex-direction:column;
  img{
    width:100%;
    display:block;
  }
  .description-comafranc{
    display:flex;
    height:206px;
    background-color: $c-mine-shaft;
    color:$c-white;
    justify-content: center;
    align-items: center;
    @include bp(max-width, $bp-medium) {
      flex-direction:column;
      height:auto;
      padding:11px 20px;
      @include bp(min-width, $bp-small) {
        padding-right: 4rem;
        padding-left: 4rem;

      }
    }


    .short-description-comafranc{
      flex:1;
      width:80%;
      padding:0 5%;
      @include bp(max-width, $bp-medium) {
        padding:0;
        padding-bottom: 15px;
        width:100%;
        letter-spacing: 0.04rem;
        @include bp(min-width, $bp-small) {
          padding-top: 3.8rem;
          padding-bottom: 2.5rem;
          @include font-size(1.8);
          font-weight:$bold-font-weight;
        }
      }
    }
    .long-description-comafranc{
      flex:2;
      font-family: Hind;
      width:80%;
      color:$c-dusty-grey;
      padding:0 5%;
      @include bp(max-width, $bp-medium) {
        padding:0;
        width:100%;
        letter-spacing: 0.04rem;
        @include bp(min-width, $bp-small) {
          padding-bottom: 4rem;
          @include font-size(1.4);
        }
      }
    }
  }


}

div.services-container{
  .service-title{
    font-weight:$bold-font-weight;
    font-size: 18px;
    @include bp(max-width, $bp-small) {
      @include font-size(1.6);
    }
  }

  ul.service-list{
    width:100%;
    display:flex;
    flex-wrap:wrap;
    padding:0;
    font-size: 0;
    @include bp(max-width, $bp-medium) {
      @include bp(min-width, $bp-small) {
        margin-bottom:0;
        .presentation-services:last-child{
          border-bottom: 0;
        }
      }
    }
    @include bp(max-width, $bp-small) {
      flex-direction: column;
      width:100%;
      margin-bottom: 0;
      border-bottom: 0;
    }

    .presentation-services{
      border-bottom: 1px solid $c-alto;
      font-size: 18px;
      width: calc(33.3333333% - 1px);
      border-left: 1px solid $c-alto;
      margin: 0;
      padding-bottom: 45px;
      @include bp(min-width, $bp-xlarge) {
        &:nth-child(3n) {
          border-right: 1px solid $c-alto;
          width: calc(33.3333333% - 2px);
        }
      }
      @include bp(max-width, $bp-xlarge) {
        width:49.8%;
        @include bp(min-width, $bp-small) {
          &:nth-child(2n), &:last-child {
            border-right: 1px solid $c-alto;
            width: calc(50% - 2px);
          }
        }
        @include bp(max-width, $bp-small) {
          flex-direction: column;
          width:100%;
          padding-bottom: 24px;
          border-left:0;
          &:last-child{
            border-bottom:none;
          }
        }
      }


      .service-title, .service-description, svg{
        text-align: left;
        padding-left: 60px;
        padding-right: 60px;
        @include bp(max-width, $bp-small) {
          padding-left: 20px;
          padding-right: 20px;
        }
      }
      .service-description{
        font-family: Hind;
        font-size:14px;
        padding-top: 20px;
        @include bp(max-width, $bp-small) {
          padding-top:1rem;
          @include font-size(1.2);
        }

        p span{
          letter-spacing: 0.03rem;
          line-height: 22px;
        }
      }



      svg{
        path, rect, polygon, circle {
          fill: $c-silver;
        }
        width:60px;
        padding-top:53px;
        padding-bottom: 40px;
        @include bp(max-width, $bp-small) {
          padding-top:26px;
          padding-bottom: 1.4rem;
          max-width: 50px;
        }
      }

    }
  }
}
