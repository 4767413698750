.comafranc-message {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  border: 1px solid $c-alto;
  background: $c-white;
  box-shadow: 3px 2px 20px 0 $c-alto;
  z-index:10;

  @include bp(max-width, $bp-small) {
    width: 100%;
  }

  .message {
    position: relative;
    padding: 10rem 13rem;
    text-align: center;

    @include bp(max-width, $bp-medium) {
      @include space('padding', 'left');
      @include space('padding', 'right');
    }
  }

  .message-thank-you {
    @include font-size(3.8);
    color: $c-mine-shaft;
    margin-bottom: 2rem;

    @include bp(max-width, $bp-small) {
      @include font-size(1.9);
    }
  }

  svg {
    position: absolute;
    right: 25px;
    top: 25px;
    cursor: pointer;

    polygon {
      fill: $c-gray;
    }
    width: 15px;
  }

  .message {
    background: none;
    color: $c-gray;
    @include font-size(1.6);
    margin: 0;

    @include bp(max-width, $bp-small) {
      @include font-size(1);
    }

    > *:first-child:before {
      content: '';
      margin: 0;
      width:0;
      display: none;
    }

    &.error {
      .message-thank-you {
        display: none;
      }
    }
  }
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  z-index: 5;
}