.footer.content {
  border: none;
  padding-left: 0;
}

.footer-top-container {
  display: flex;
  @include space('padding', 'left');
  @include space('padding', 'right');
  @include space('padding', 'top');
  @include space('padding', 'bottom');

  @include bp(max-width, $bp-small) {
    padding-top: 2rem;
  }
  align-items: center;
  border-right: 1px solid $c-mercury;
  border-left: 1px solid $c-mercury;
  border-top: 1px solid $c-mercury;

  @include bp(max-width, $bp-large) {
    padding-bottom: 0;
  }

  ul {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;

    @include bp(max-width, $bp-medium) {
      flex-wrap: wrap;
      padding-top: 30px;
    }

    @include bp(max-width, $bp-small) {
      padding-top: 0;
    }

    li {
      @include bp(max-width, $bp-medium) {
        flex-basis: 30%;
        text-align: center;
        padding-bottom: 30px;
        padding-left: 5px;
        padding-right: 5px;
      }

      @include bp(max-width, $bp-small) {
        width: 50%;
        padding-bottom: 1.8rem;
      }

      @include bp(max-width, $bp-xsmall) {
        width: 45%;
      }
    }

    a {
      display: block;
      transition: all .5s;

      &.inactive {
        opacity: 0.3;
      }
    }
  }
}

.footer-bottom-container {
  background-color: $c-congress-blue;
  color: $c-white;
  @include space('padding', 'left');
  @include space('padding', 'right');
  @include space('padding', 'top');
  @include space('padding', 'bottom');
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  @include bp(min-width, $bp-medium2) {
    padding-bottom: 9rem;
  }

  @include bp(min-width, $bp-xlarge) {
    padding-bottom: 5rem;
  }

  @include bp(max-width, $bp-medium) {
    height: auto;
    overflow: auto;
    padding-top: 4.8rem;
    padding-bottom: 5rem;
    text-align: center;
    flex-direction: column;
    .footer-small{
      text-align: center;
    }
  }



  .footer-bottom-left {
    @include font-size(1.3);

    @include bp(max-width, $bp-small) {
      @include font-size(1.1);
    }
  }

  .footer-bottom-center {

    @include bp(max-width, $bp-medium) {
      margin: 15px 0;
    }

    @include bp(max-width, $bp-medium2) {
      margin: 15px 0;
    }

    @include bp(min-width, $bp-medium2) {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    @include bp(min-width, $bp-xlarge) {
      position: relative;
      left: auto;
      transform: translateX(0);
    }
  }

  .footer-bottom-right {
    font-weight: $medium-font-weight;
    text-align: right;
    color: $c-rock-blue;

    @include bp(max-width, $bp-small) {
      @include font-size(1.1);
      text-align: center;
    }

    a {
      color: $c-rock-blue;

      @include bp(max-width, $bp-small) {
        padding: 1rem 0;
        display: block;
      }
    }

    .footer-link-separator {
      margin: 0 10px;

      @include bp(max-width, $bp-small) {
        display: none;
      }
    }

    .footer-small {
      @include font-size(1.2);
      margin-top: 2rem;
    }

    p {
      @include bp(max-width, $bp-small) {
        margin: 0;
      }
    }
  }
}

.footer.content .social-links {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  list-style: none;

  @include bp(max-width, $bp-medium) {
    justify-content: space-between;
    padding-bottom: 5rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  @include bp(max-width, $bp-small) {
    margin: 20px auto;
    justify-content:center;
    padding-bottom: 0;
  }

  li {
    margin-right: 2.1rem;
  }

  svg {
    width: 30px;
    @include bp(max-width, $bp-medium) {
      width: 36px;
    }
    @include bp(max-width, $bp-small) {
      width: 30px;
    }


    path {
      fill: $c-white;
    }
  }
}

@include bp(max-width, $bp-medium) {
  .footer-top-container {
    height: auto;
    padding-top: 4rem;
    ul li{
      width:50%;
      @include bp(max-width, $bp-small) {
        img{
          width: 90%;
        }
      }

    }
  }
  .footer.content .social-links {
    li {
      display: inline-block;
    }
    li:last-child {
      margin-right: 0;
    }
  }

  .footer-bottom-container .footer-bottom-right .footer-small {
    font-size: inherit;
  }
  .footer-bottom-container .footer-bottom-right .footer-small {
    margin: 0;
  }
}
