.page-header{
  position:relative
}

.homepage-container {


  .homepage-row {
    display: flex;
    justify-content: space-between;
    @include space('margin', 'right');
    @include space('margin', 'left');

    @include bp(max-width, $bp-large) {
      justify-content: center;
      display: block;
      text-align: center;
    }
  }
  .homepage-row-full {
    display: flex;
    width: 100%;
    margin: 5rem 0;
  }

  .homepage-title {
    margin-top: 8.2rem;
    margin-bottom: 8.2rem;
    @include bp(max-width, $bp-medium) {
      margin-bottom: 4rem;
    }
    @include bp(max-width, $bp-small) {
      margin-top: 4rem;
      margin-bottom: 6rem;
    }
  }

  .homepage-title-left {
    width: 377px;
    margin-right: 10rem;

    @include bp(max-width, $bp-large) {
      width: 100%;
      padding-right: 0;
      padding-top: 25px;
      padding-bottom: 47px;
    }

    @include bp(max-width, $bp-medium) {
      picture img{
        width:380px;
      }
      padding-top: 50px;
      padding-bottom: 50px;
    }

    @include bp(max-width, $bp-small) {
      padding-bottom: 0;
    }

    img {
      @include bp(max-width, $bp-large) {
        max-width: 100%;
        margin-left: 20px;
        margin-right: 20px;
      }

      @include bp(max-width, $bp-small) {
        margin: 0;
      }
    }
    .mini-logos {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 5px;
      @include bp(max-width, $bp-medium) {
        margin-top:1.6rem;
        img{
          height:3.5rem;
          width:auto;
          margin:0 20px;
        }
      }
      @include bp(min-width, $bp-medium) {
        img:nth-child(2) {
          margin-left: 40px;
        }
      }

      @include bp(max-width, $bp-large) {
        justify-content: center;
      }

      @include bp(max-width, $bp-small) {
        margin: 10px auto;
        width: 100%;
        justify-content: center;
        img{
          margin:0;
          height:auto;
        }
        img:nth-child(2){
          margin-left: 25px;
        }

      }

      img {
        @include bp(max-width, $bp-small) {
          width:calc(33.3333333% - 2rem);
        }
      }
    }
  }
  .homepage-title-right {
    flex: 1;
    align-self: center;
    max-width: 960px;
    text-transform : none;

    @include bp(max-width, $bp-small) {
      display: none;
    }

    .h1 {
      font-weight:$bold-font-weight;
      color: $c-mine-shaft;
      @include font-size(1.6);
      margin-bottom: 2rem;
      text-transform : none;
      @include bp(max-width, $bp-small) {
        font-size:1.2rem;
        margin-bottom: 1.4rem;
        padding-top: 15px;
      }
    }

    .homepage-description {
      color: $c-gray;
      font-weight: $light-font-weight;
    }
  }
}
.homepage-row {
  margin-top:4.3rem;
  margin-bottom:4.3rem;

  @include bp(max-width, $bp-small) {
    margin-top: 0;
    margin-bottom: 0;
  }
}
.homepage-categories {

  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    padding: 0;
    margin-bottom: 0;

    .homepage-categories-item {
      transition: all .5s;
      width: 25%;

      img {
        display: block;
        width: 100%;
      }

      &.inactive {
        opacity: 0.7;
      }

      .homepage-category-name {
        width: 100%;
        font-weight: $medium-font-weight;
        @include font-size(1.8);
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid $c-mercury;
        border-right: 1px solid $c-mercury;
        height: 68px;
        align-self: center;
        @include bp(max-width, $bp-small) {
          @include font-size(1.2);
          height:45px;
        }

      }

      &:last-child {
        .homepage-category-name {
          border-right: none;
        }
      }
    }
  }

  a {
    display: block;
    color: $c-mine-shaft;

    &:hover {
      color: $c-white;
      text-decoration: none;
      background-color: $c-congress-blue;
    }
  }
}

// Shops slider
.slider-container {
  display: flex;

  .slide-control {
    display: flex;
    cursor: pointer;
    padding: 1rem;

    @include bp(max-width, $bp-small) {
      align-self: flex-start;
      padding-top: 0;
      padding-bottom: 0;
    }

    &.slide-prev {
      margin-right: 2rem;
    }
    &.slide-next {
      margin-left: 2rem;
    }

    &:hover {
      svg {
          path {
            fill: $c-congress-blue;
          }
        }
    }
  }

  .slide-caption-content {
    display: flex;
    width: 100%;

    @include bp(max-width, $bp-medium) {
      display: flex;
      justify-content: space-between;
    }

    @include bp(max-width, $bp-small) {
      flex-direction: column;
    }

    .slide-caption {
      @include bp(max-width, $bp-xlarge) {
        width: auto;
      }
      @include bp(max-width, $bp-small) {
        width: 100%;
        justify-content: center;
      }

      &.slide-caption-left {
        @include bp(max-width, $bp-xlarge) {
          padding-right: 15px;
        }
        @include bp(max-width, $bp-small) {
          padding-right: 0;
        }
      }
    }
  }
}
.slider-description {
  width: 33%;
  background: $c-prussian-blue;
  color: $c-white;
  font-weight: $light-font-weight;
  line-height:22px;
  display:flex;
  flex-direction:column;
  justify-content:space-around;
  padding-left: 3%;
  padding-right: 3%;

  .slider-description-nbshop{
    line-height:30px;
    @include bp(max-width, $bp-medium) {
      line-height:40px;
    }
  }

  @include bp(max-width, $bp-medium) {
    @include space('padding', 'top');
    @include space('padding', 'bottom');
  }

  @include bp(max-width, $bp-small) {
    line-height: 16px;
  }

  div {
    display: inline-block;
  }

  .comafranc-dark-blue-button {
    border-radius: 25px;
  }
}
.slider {
  max-width: 67%;

  .comafranc-button {
    border-radius: 25px;
  }
}

.expo-rooms {
  margin-top: 0;
  padding-top: 3.2rem;
  margin-bottom: 58px;
  @include bp(max-width, $bp-xlarge) {
    margin-bottom:0;
  }
  @include bp(max-width, $bp-medium) {
    padding-top:6.3rem;
  }

  @include bp(max-width, $bp-small) {
    @include space('padding', 'top');
    @include space('padding', 'bottom');
    margin-bottom:0;
    padding-bottom: 0 !important;
  }
  .homepage-expo-title {
    text-align: center;
    font-weight: $semibold-font-weight;
    @include font-size(3.6);
    color: $c-mine-shaft;
    line-height: 31px;
    margin-bottom: 65px;

    @include bp(max-width, $bp-small) {
      @include font-size(1.9);
      margin-bottom: 3.5rem;
    }
  }
  .homepage-expo-subtitle {
    text-align: center;
    font-weight: $light-font-weight;
    @include font-size(2.4);
    color: $c-mine-shaft;
    margin-bottom: 3.9rem;
    line-height: 30px;

    @include bp(max-width, $bp-small) {
      @include font-size(1.3);
      @include space('margin', 'bottom');
    }
  }
}

@include bp(max-width, $bp-medium) {
  .top-container .menu-links, .top-container .configurator-link {
    display: none;
  }
  .homepage-container .homepage-title-right {
    text-align: left;
    @include space('margin', 'top');
  }

  /**
   * TODO
   */
  .homepage-categories ul {
    display: block;
    text-align: center;

  }

  .homepage-categories ul .homepage-categories-item .homepage-category-name {
    display: block;
    text-align: center;
    line-height:68px;
  }

  .homepage-categories ul .homepage-categories-item {
    display: block;
  }

  .homepage-container .homepage-row-full {
    display: block;
  }

  .columns {
    display: block;
  }

  .homepage-categories-item {
    &.slick-slide {
      opacity: 0.5;

      @include bp(max-width, $bp-medium) {
        opacity:1;
      }
    }
    &.slick-current {
      opacity: 1;
    }
  }
}

// Catalog request
.home-catalogrequest-container {
  display: flex;
  justify-content: center;
  margin-bottom: 0;
  margin-top:0;
  @include bp(max-width, $bp-small) {
    padding-bottom: 1.3rem;
  }

  img {
    display: block;
  }

  .home-catalogrequest-description {
    color: $c-gray;
    line-height: 22px;

    @include bp(max-width, $bp-small) {
      line-height: 16px;
    }

    .comafranc-button {
      box-shadow: 3px 2px 20px 0 $c-alto;
    }
  }

  .home-catalogrequest {
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 0;
    border-top: 1px solid $c-mercury;
    border-left: 1px solid $c-mercury;
    border-right: 1px solid $c-mercury;
    @include bp(max-width, $bp-xlarge) {
      flex-direction: column;
      padding-bottom: 5rem;
      @include bp(max-width, $bp-small) {
        padding-top:0;
        padding-bottom: 2.4rem;
      }
    }

    .home-catalogrequest-img {
      width:50%;
      @include bp(max-width, $bp-xlarge) {
        width:100%;
      }
      @include bp(max-width, $bp-xlarge) {
        margin-bottom: 44px;
      }
    }

    .home-catalogrequest-content {
      box-sizing: border-box;
      padding: 0 60px;
      width:50%;
      @include bp(max-width, $bp-xlarge) {
        width:100%;
      }
      .comafranc-button{
        @include bp(max-width, $bp-small) {
          margin-top: 3rem;
        }
        @include bp(max-width, $bp-medium) {
          margin-top: 3rem;
        }

      }

      @include bp(max-width, $bp-small) {
        margin-top: 2.75rem;
        padding:0 2.5rem;
      }
      .home-catalogrequest-description{
        letter-spacing: 0.05rem;
      }
      .home-catalogrequest-title {
        margin-bottom: 3rem;

        @include bp(max-width, $bp-small) {
          margin-top: 1.5rem;
          margin-bottom:1.8rem;
          font-size: 1.6rem;
        }
        @include bp(max-width, $bp-medium) {
          margin-bottom:2.2rem;
        }
      }
    }

    a.comafranc-button {
      margin-top: 5rem;
      color:$c-congress-blue-light !important;
    }
    a.comafranc-button:hover {
      color:$c-white !important;
    }
  }

  .home-appointment {
    position: relative;
    flex: 1;
    border: solid 1px $c-mercury;

    width: 90%;
    margin: auto;

    img {
      margin: auto;
    }

    .home-appointment-content {
      text-align: center;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      color: $c-white;
      width: 100%;

      .comafranc-button {
        margin-top: 3rem;
      }

      .home-appointment-subtitle {
        font-weight: $light-font-weight;
        @include font-size(2.6);

        @include bp(max-width, $bp-small) {
          @include font-size(1.4);
        }
      }
      .home-appointment-title {
        font-weight: $semibold-font-weight;
        @include font-size(3.2);

        @include bp(max-width, $bp-small) {
          @include font-size(1.7);
        }
      }
    }
  }
}

// Find a pro
.home-findapro {
  color: $c-white;
  background-image: radial-gradient(#0079aa, $c-midnight);
  position: relative;
  @include space('padding', 'left');
  @include space('padding', 'right');
  @include space('padding', 'top');
  @include space('padding', 'bottom');

  @include bp(max-width, $bp-small) {
    margin-bottom: 0;
  }

  .home-findapro-content {
    width: 55%;

    .home-findapro-subtitle {
      margin-bottom: 2rem;
      margin-top: 5rem;
      font-weight: $semibold-font-weight;
      @include font-size(1.6);

      @include bp(max-width, $bp-small) {
        @include font-size(1.2);
        margin-bottom: 1.75rem;
        margin-top: 3.5rem;
      }
    }
  }

  .home-findapro-title {
    font-weight: $light-font-weight;
    @include font-size(6.8);

    @include bp(max-width, $bp-xlarge) {
      @include font-size(3.8);
    }

    @include bp(max-width, $bp-small) {
      text-align: center;
      line-height: 65px;
      margin-bottom: 2rem;
    }
  }

  img {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  ul {
    padding-left: 0;
  }

  .home-jobs-list-container {
    overflow: auto;
    display: flex;
    justify-content: space-between;

    @include bp(max-width, $bp-small) {
      overflow: visible;
      opacity: 0.7;
      display: block;
    }

    ul {
      @include bp(max-width, $bp-xlarge) {
        margin-bottom:0;
      }

      @include bp(max-width, $bp-small) {
        width: 100%;
      }
    }

    li {
      @include bp(max-width, $bp-small) {
        display: flex;
        width: 50%;
        height: 34px;
        margin-bottom: 1.5rem;
        align-items: center;
        float: left;
      }
    }

    .comafranc-checkbox-label {
      margin-left: 0.9rem;

      @include bp(max-width, $bp-xlarge) {
        margin-left: 5px;
      }

      @include bp(max-width, $bp-medium) {
        opacity: 0.7;
      }

      @include bp(max-width, $bp-small) {
        margin-left: 0;
      }
    }
  }

  .home-jobs-button-container {
    @include bp(max-width, $bp-small) {
      width:100%;
      clear: both;
    }
  }

  .comafranc-checkbox {
    border: 1px solid $c-white;
    background: transparent;

    label {
      background: transparent;
      top: 0;
      left: 0;
    }
  }

  .comafranc-button {
    font-weight: $regular-font-weight;
    font-family: Hind, Helvetica, Arial, sans-serif;

    @include bp(max-width, $bp-small) {
      padding-left: 3.5rem;
      padding-right: 3.5rem;
    }
  }
}
.slick-slide img {
  width: 100%;
}
.slider-description {
  .slider-description-nbshop {
    font-size: 30px;
    font-weight: $bold-font-weight;
  }
  .slider-description-content {
    font-weight: $light-font-weight;
    font-size: 14px;
    @include bp(max-width, $bp-small) {
      font-size: 10px !important;
    }
  }
}
@include bp(max-width, $bp-xlarge) {

  .slider {
    display: block;
    width: 100%;
    max-width: 100%;
  }
  .slider-container {
    display: block;
  }
  .slider-description {
    width: 100%;
    padding-top: 4rem;
    padding-bottom: 4rem;
    @include bp(max-width, $bp-large) {
      display:flex;
      flex-direction: column;
      padding: 5.5rem 4rem 4.5rem 4rem;
    }

    .slider-description-nbshop{
      font-size: 30px;
      font-weight:$bold-font-weight;
      @include bp(max-width, $bp-small) {
        font-size: 16px;
        padding-bottom: 1.2rem;
        margin-bottom: 0;
        line-height: 22px;
      }
      @include bp(max-width, $bp-xlarge) {
        padding-bottom: 3rem;
      }
    }
    .slider-description-content{
      margin-bottom: 90px;
      font-weight: $light-font-weight;
      font-size: 14px;
      @include bp(max-width, $bp-small) {
        font-size: 10px;
        padding-top: 10px;
        margin-bottom: 30px;
      }
      @include bp(max-width, $bp-medium) {
        margin-bottom: 4.6rem;
      }
    }

    @include bp(max-width, $bp-small) {
      padding-top:3rem;
      padding-bottom: 0;
      a{
        margin-bottom: 2.9rem;
      }
    }
  }

  .home-catalogrequest, .home-appointment {
    width: 100%;
    display: block;
  }
  .home-catalogrequest-container {
    display: block;
  }

  .home-findapro .home-findapro-content {
    width: 100%;
  }
  .home-findapro img {
    display: none;
  }
  .home-findapro .comafranc-button {
    margin-top: 15px;
  }
  .home-findapro {
    font-size: 1.2rem;
  }
}


@include bp(max-width, $bp-small) {
  .slide-bottom .slide-nb {
    display: block;
    padding: 15px;
    box-sizing: border-box;
  }
  .slide-bottom .slide-caption-container {
    justify-content: center;
  }
  .slide-bottom {
    width: 100%;

    .slide-nb {
      display: block;
      width: 100%;
    }
  }
}



.title-mobile {
  @include space('margin', 'left');
  @include space('margin', 'right');
}

//Services
.home-service-container{
  border-top:1px solid #e2e2e2;
  border-left:1px solid #e2e2e2;
  border-right:1px solid #e2e2e2;
  padding-top:50px;
  padding-bottom: 70px;
  margin: 0;
  display:flex;
  flex-direction: column;
  align-content: center;

  @include bp(max-width, $bp-medium) {
    padding-top:6.8rem;
    padding-bottom: 5rem;

  }
  @include bp(max-width, $bp-small) {
    padding-right: 2.4rem;
    padding-left: 2.4rem;
    padding-bottom: 5rem;

  }


}
.home-services-description{
  line-height: 22px;
  font-size: 14px;
  text-align: center;
  width:70%;
  margin:30px auto;
  color:$c-gray;
  margin-bottom: 50px;
  @include bp(max-width, $bp-small) {
    font-size:10px;
    margin:20px 0;
    width:100%;
    line-height: 15px;
  }
  @include bp(max-width, $bp-medium) {
    margin-top:2.2rem;
    margin-bottom:4.5rem ;
  }
}

.service-title {
  font-weight:$bold-font-weight;
  color:$c-mine-shaft;
  font-size:26px ;
  margin:0 auto;
  text-align: center;
  @include bp(max-width, $bp-small) {
    font-size:16px;
  }

}

ul.services-menu{
  width: 90%;
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  padding-left:0;
  text-align: center;
  margin: 0 auto;
  @include bp(max-width, $bp-small) {
    flex-wrap:wrap;
  }
  @include bp(max-width, $bp-medium) {
    display:block;
  }

  .homepage-services {
    display: flex;
    flex-direction:column;
    justify-content: space-between;

    .svg-service{
      svg {
        path, rect, polygon, circle {
          fill: $c-silver;
        }
      }
    }

    .service-icon-title {
      height: 40px;
      color: $c-mine-shaft;
    }

    @include bp(max-width, $bp-small) {
      width:30%;
    }
    svg {
      path {
        fill: $c-mine-shaft;
      }
    }
    svg {
      width:48px;
      max-width: 63px;
    }

    &:nth-child(1) {
      svg {
        width: 62px;
      }

    }

    &:nth-child(2) {
      svg {
        width: 53px;
      }
    }

    &:nth-child(3) {
      svg {
        width: 50px;
        .cls-1{
          fill:$c-white;
        }
      }
    }

    &:nth-child(4) {
      svg {
        width: 63px;
      }
    }

    &:nth-child(5) {
      svg {
        width: 50px;
      }
    }

    &:nth-child(6) {
      svg {
        width: 45px;
      }
    }

    &:nth-child(7) {
      svg {
        width: 47px;
      }
    }

    &:nth-child(8) {
      svg {
        width: 50px;
      }
    }

    &:nth-child(9) {
      svg {
        width: 48px;
      }
    }
    &:nth-child(10) {
      svg {
        width: 48px;
      }
    }
    &:nth-child(11) {
      svg {
        width: 48px;
      }
    }
    &:nth-child(12) {
      svg {
        width: 48px;
      }
    }
  }
}
.service-button{
  text-align: center;
  a.comafranc-button {
    margin-top: 5rem;
    color:$c-congress-blue-light !important;

    @include bp(max-width, $bp-medium) {
      margin-top: 1.5rem;
    }

    @include bp(max-width, $bp-small) {
      margin-top: 1rem;
    }
  }
  a.comafranc-button:hover {
    color:$c-white !important;
  }
}
.home-services-description{

}

//banner
.home-bathroom-container{
  display:flex;
  position:relative;
  z-index: 5;
  width:100%;
  border-left: 1px solid $c-mercury;
  border-right: 1px solid $c-mercury;
  margin-bottom: 2rem;

  @include bp(max-width, $bp-medium) {
   display:none;
  }

}
.bathroom-project-right{
  position:absolute;
  left:50%;
  top:20%;

  @include bp(max-width, $bp-large) {
    top:50px;
  }


  a.comafranc-button {
    color:$c-congress-blue-light !important;
  }
  a.comafranc-button:hover {
    color:$c-white !important;
  }
}
.home-banner-title{
  font-weight:$bold-font-weight;
  font-size:2rem;
  color:$c-congress-blue;
}
.home-banner-subtitle{
  font-weight:$bold-font-weight;
  font-size:3.5rem;
  color:$c-congress-blue;
  margin-bottom: 1%;
}
.home-banner-description{
  font-size: 1.5rem;
  margin-bottom: 5.3rem;
  color:$c-mine-shaft-text;
  @include bp(max-width, $bp-large) {
    margin-bottom: 0;
    display:none;
  }
}