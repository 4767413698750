.blog-index-index {
  .page-title-wrapper {
    text-align: center;
  }
}

.blog-view-details {
  @include bp(max-width, $bp-medium) {
    .page-main {
      margin-top: 0;
    }
  }
  .page-title-wrapper {
    @include bp(min-width, $bp-medium) {
      margin-top: 6rem;
    }

    .page-title {
      @include bp(max-width, $bp-medium) {
        max-width: 80%;
      }

      @include bp(max-width, $bp-small) {
        max-width: 100%;
      }
    }
  }
}

.blog-articles-container {
  .fullsize-page-seo {
    padding-top: 1.4rem;
    padding-bottom: 1.4rem;
    box-sizing: initial;

    @include bp(max-width, $bp-medium) {
      border-bottom:none;
      display: block;
      @include space('padding', 'bottom');
      @include space('padding', 'top');
      @include space('padding', 'left');
      @include space('padding', 'right');
    }
  }

  .blog-items {
    ul {
      padding: 0;
      display: flex;
      border-left: 1px solid $c-mercury;
      border-right: 1px solid $c-mercury;
      flex-wrap: wrap;
      margin: 0;

      @include bp(max-width, $bp-medium) {
        border: none;
      }

      li {
        width: calc(33.3333333% - 1px);
        background: $c-concrete;
        margin-top: 3rem;
        margin-bottom: 0;
        margin-right: 0.1rem;

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          margin-top: 0;
        }


        @include bp(max-width, $bp-medium) {
          width: 50%;
          margin: 0 0 35px 0;
          float: left;
          display: inline-block;
        }

        @include bp(max-width, $bp-small) {
          margin-bottom: 2rem;
        }

        &:nth-child(3n) {
          margin-right:0;
        }

        &:hover {
          box-shadow: 0 3px 20px 7px $c-alto;
          position: relative;
          background: $c-white;
        }

        a {
          color: $c-gray;
          &:hover {
            text-decoration: none;
          }
        }

        .blog-item-content {
          padding-top: 4rem;
          padding-bottom: 4rem;

          @include space('padding', 'right');
          @include space('padding', 'left');

          @include bp(max-width, $bp-small) {
            padding-top: 2rem;
            padding-bottom: 2rem;
          }
        }

        .blog-date {
          @include font-size(1.2);
          color: $c-sylver-chalice;

          @include bp(max-width, $bp-small) {
            @include font-size(1);
          }
        }

        .blog-title {
          margin-top:0.5rem;
          font-weight: $bold-font-weight;
          @include font-size(1.6);
          color: $c-mine-shaft;
          letter-spacing: 0.06rem;
          line-height: 2rem;
          display: block;

          @include bp(max-width, $bp-small) {
            @include font-size(1.2);
            margin-bottom: 0;
          }
        }
      }
    }
  }
  @include bp(max-width, $bp-medium) {

    &.fullsize-page .fullsize-page-seo {
        h1 {
          margin-bottom:20px;
        }
    }


    .blog-items ul li {
      &:nth-child(2n) {
        margin-right:0 !important;
        margin-left:0.05rem !important;
        @include bp(max-width, $bp-medium) {
          margin-left: 0 !important;
          margin-right: 0 !important;
        }
      }

      .blog-description {
        display: none;
      }
    }
  }
  @include bp(max-width, $bp-small) {
    &.fullsize-page .fullsize-page-seo {
      border-bottom:none;
      display: block;

      h1 {
        margin-bottom:20px;
      }
    }
    .blog-items ul li {
      width:100%;
      float: none;
      display: inline-block;

      margin-right: 0 !important;

      &:nth-child(2n) {
        margin-left:0 !important;
      }
    }
    .blog-item-content > div {
      display: none;
    }
    .blog-item img {
      width:100%;
    }
  }
}
.article-container {
  border-left: 1px solid $c-mercury;
  border-right: 1px solid $c-mercury;
  border-top: 1px solid $c-mercury;

  @include bp(max-width, $bp-medium) {
    border-top: none;
  }

  @include bp(max-width, $bp-small) {
    border-top: 1px solid $c-mercury;
  }

  h2 {
    font-weight: $semibold-font-weight;
    @include font-size(1.8);
    color: $c-mine-shaft;


    @include bp(max-width, $bp-small) {
      @include font-size(1.2);
      margin-top: 1rem;
    }
  }

  .article-header {
    display: table;

    @include bp(max-width, $bp-small) {
      display: flex;
      flex-direction: column;
      margin-top: 25px;
      @include space('margin', 'left');
      @include space('margin', 'right');
    }

    .blog-date {
      display: table-cell;
      box-sizing: border-box;
      color: $c-gray;
      padding: 6.4rem 1rem 2.5rem 5rem;
      width: 120px;
      border-bottom: 1px solid $c-mercury;

      @include bp(max-width, $bp-medium) {
        padding: 0 0 2.5rem 3rem;
        width: auto;
      }

      @include bp(max-width, $bp-small) {
        width: 100%;
        display: block;
        border-bottom: none;
        padding-left: 0;
        padding-bottom: 0;
      }
    }

    h2 {
      display: table-cell;
      padding-left:15rem;

      @include bp(max-width, $bp-medium) {
        padding-left: 75px;
      }

      @include bp(max-width, $bp-small) {
        width: 100%;
        display: block;
        padding-left: 0;
      }
    }
  }

  .article-content-container {
    margin: 1rem auto 6rem;
    width: 902px;

    @include bp(max-width, $bp-large) {
      width: 100%;
    }

    @include bp(max-width, $bp-medium) {
      margin-top: 25px;

      padding-right: 10%;
      padding-left: 10%;
    }

    @include bp(max-width, $bp-small) {
      padding-right: 0;
      padding-left: 0;
    }

    @include bp(max-width, $bp-small) {
      width: 100%;
    }

    .article-content {
      @include bp(max-width, $bp-medium) {
        @include space('margin', 'left');
        @include space('margin', 'right');
      }
    }

    ul {
      list-style: disc;
    }

    img {
      margin-bottom: 4.6rem;

      @include bp(max-width, $bp-medium) {
        width: 100%;
      }
    }
  }
}
