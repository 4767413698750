.shop-index-index {
  .page-title-wrapper {
    margin-bottom: 3.4rem;
    margin-top: 4.2rem;
    h1{
      width:100%;
      margin-left:0;
    }
    @include bp(max-width, $bp-medium) {
      text-align: center;
    }

    @include bp(max-width, $bp-small) {
      .page-title span{
        font-weight: $bold-font-weight !important;
      }
      margin-bottom: 2rem;
      margin-top: 0;
      padding-top: 21px;
    }
  }
}
.shop-view-details {

  @include bp(max-width, $bp-medium) {
    @include bp(min-width, $bp-small) {
      .breadcrumbs{
        margin-top: 54px;
      }
    }
  }
  .page-title-wrapper {
    margin-bottom: 3.4rem;
    margin-top: 4.2rem;
    @include bp(max-width, $bp-medium) {
      @include bp(min-width, $bp-small) {
        margin-top:4rem !important;
        margin-bottom:4rem !important;
      }
    }

    @include bp(max-width, $bp-small) {
      .page-title span{
        font-weight: $bold-font-weight !important;
      }
      margin-bottom: 2rem;
      margin-top: 0;
      padding-top: 21px;
      text-align: center;
    }
  }
}

.shop-container-logos-photo{
  picture img{
    display:block;
    margin:0;
    padding: 0;
  }
  display:flex;
  width:100%;
  @include bp(max-width, $bp-xlarge) {
    flex-direction: column;
  }


  .shop-image-aubade {
    flex:1;
    @include bp(max-width, $bp-xlarge) {
      margin:0 auto;
    }
  }
  .shops-logo-container{
    flex:1;
  }
}
.allshop-description{
  width:80%;
  margin: 0 auto;
  border-top:1px solid $c-mercury ;
  padding-top: 49px;
  @include bp(max-width, $bp-xlarge) {
    width:90%;
    padding-top: 2.5rem;
    padding-bottom: 4.3rem;
  }
  @include bp(max-width, $bp-small) {
    padding-top: 2rem;
    padding-bottom: 2.5rem;
  }
  .allshop-title{
    font-weight:$bold-font-weight;
    font-size:20px ;
    color:$c-mine-shaft;
    padding-bottom: 2rem;
    @include bp(max-width, $bp-medium) {
      @include bp(min-width, $bp-small) {
        padding-bottom: 1rem;
        margin-top: 2rem;
      }
    }
    @include bp(max-width, $bp-small) {
      @include font-size(1.2);
      margin:0;
      padding-bottom: 1.5rem;
    }
  }

}



.shop-image {
  max-height: 545px;
  margin: 0 auto;
  display: block;
}
.shop-subcontent-container {
  display: flex;
  align-items: center;
  color: $c-mine-shaft;

  @include bp(mim-width, $bp-medium) {
    @include space('padding', 'left');
    @include space('padding', 'right');
    @include space('padding', 'bottom');
    @include space('padding', 'top');
  }

  border-left: 1px solid $c-mercury;
  border-right: 1px solid $c-mercury;

  .shop-subtitle {
    flex: 1;
  }
  .shop-description {
    flex: 2;
    @include bp(max-width, $bp-small) {
      padding-right: 1.4rem;
      padding-left: 1.4rem;;
    }

    p{
      color:$c-gray;
    }

    @include bp(max-width, $bp-medium) {
      display: block;
      width: 100%;
    }

    @include bp(max-width, $bp-small) {
      line-height: 16px;
    }
  }
}
.shop-info-container {
  display: flex;
  width: 100%;
  color: $c-mine-shaft;

  @include bp(max-width, $bp-large) {
    flex-wrap: wrap;
  }
  @include bp(max-width, $bp-small) {
    flex-direction: column;
  }

  .shop-info-small {
    width: 50%;
    display:flex;
    color:white;
    padding-top:60px;
    padding-bottom: 60px;

    @include bp(max-width, $bp-small) {
      width:100%;
      padding-top:3rem;
      padding-bottom: 3rem;
    }


    .shop-contact{
      font-weight: $light-font-weight;
      font-size: 12px;
    }

    .shop-info-icon{
      margin: auto;
      flex:1;
      text-align: center;
    }
    .shop-info-detail{
      margin: auto;
      flex:2;
      padding-left: 30px;
    }


    &:first-child {
      background: $c-congress-blue-light;
      svg {
        path {
          fill: $c-glacier ;
        }
      }
      .shop-info-detail {
        border-left: 2px solid $c-glacier;
      }
    }
    &:last-child {
      background: black;
      svg {
        path {
          fill: $c-gray ;
        }
      }
      .shop-info-detail {
        border-left: 2px solid $c-gray;
      }
    }


    > div {
      &:first-child {
        @include bp(max-width, $bp-large) {
          flex: 1;
          text-align: center;

        }
      }
      &:last-child {
        @include bp(max-width, $bp-large) {
          flex: 2;
        }
      }
    }

    a {
      text-decoration: underline;
      color: $c-white;
      margin-top:1.5rem;
      display: block;

      @include bp(max-width, $bp-small) {
        @include font-size(0.9);
      }
    }
  }
  .shop-info-big {
    width: 50%;
    background: $c-regal-blue;
    text-align: center;
    color: $c-white;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include bp(max-width, $bp-large) {
      width: 100%;
    }

    .comafranc-button {
      margin-top:3rem;
      margin-bottom: 1.4rem;
    }
  }

  .shop-info-icon {
    margin-bottom: 1.3rem;
  }

  .shop-label {
    font-weight: $bold-font-weight;
    @include font-size(1.6);

    @include bp(max-width, $bp-small) {
      @include font-size(1.1);
    }

    &.shop-label-small {
      @include font-size(1.4);

      @include bp(max-width, $bp-small) {
        @include font-size(1);
      }
    }
  }
  .shop-label-email {
    margin-top: 2.2rem;
    display: block;
  }
}
.shop-opening-container {
  display: flex;
  border-left: 1px solid $c-mercury;
  border-bottom: 1px solid $c-mercury;
  width:100%;
  box-sizing: border-box;
  color: $c-mine-shaft;
  justify-content: space-between;

  @include bp(max-width, $bp-small) {
    flex-direction: column;
  }

  .shop-opening {
    flex:1;
    border-right: 1px solid $c-mercury;
    @include bp(min-width, $bp-small) {
      @include space('padding', 'top');
      @include space('padding', 'bottom');
      @include space('padding', 'left');
      @include space('padding', 'right');
    }

    @include bp(max-width, $bp-small) {
      padding: 2.4rem 1.9rem 2rem;
    }
    @include bp(min-width, $bp-small) {
      @include bp(max-width, $bp-medium) {
        padding-top: 4rem;
      }
    }

    ul {
      padding-left: 0;
    }

    img {
      display: block;
      margin-bottom: 3.5rem;
      @include bp(min-width, $bp-small) {
        @include bp(max-width, $bp-medium) {
          height:4.3rem;
          width:auto;
        }
      }
      @include bp(max-width, $bp-small) {
        width:9rem;
        margin-bottom: 2.2rem;
      }
    }
  }
}
.virtual-visit-container {
  iframe {
    display: block;
    width: 100%;
    height: 761px;
    border: none;
    margin: 0;
  }

  .virtual-visit-content {
    display: flex;
    align-items: center;
    padding: 7rem 6.4rem 5.5rem;
    border-left: 1px solid $c-mercury;
    border-right: 1px solid $c-mercury;

    @include bp(max-width, $bp-large) {
      flex-direction: column;
      align-items: flex-start;
      @include space('padding', 'left');
      @include space('padding', 'right');
      @include space('padding', 'top');
      @include space('padding', 'bottom');
    }

    .virtual-visit-title {
      flex: 1;
    }
    .virtual-visit-description {
      flex: 2;
      color: $c-gray;

      @include bp(max-width, $bp-small) {
        margin-top: 2rem;
        line-height: 16px;
      }
    }
  }
}
.shop-map-container {
  #map {
    height: 407px;
    @include bp(max-width, $bp-small) {
      height:290px;
    }
  }
}
.shop-pictures-container {
  position: relative;
  border-left: 1px solid $c-mercury;
  border-right: 1px solid $c-mercury;
  svg{
    padding: 18px;
    background-color: $c-white;
    @include bp(max-width, $bp-medium2) {
      padding:10px;
    }
  }

  .h2 {
    @include space('padding', 'left');
    @include space('padding', 'right');
    padding-top: 9rem;
    @include space('margin', 'bottom');

    @include bp(max-width, $bp-large) {
      padding-bottom: 0;
      padding-top: 4rem;
    }
  }
  .shop-picture-prev {
    left: 0;
  }
  .shop-picture-next {
    right: 0;
  }

  .shop-picture-control {
    background: #f0f0f0;
    cursor: pointer;
    z-index: 1;
    height: 100%;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: $margin-max;
  }
}

// Shops list
.shop-items-container {
  h1{
    margin:0 auto;
  }
  .shop-items {
    border-left: 1px solid $c-mercury;
    border-right: 1px solid $c-mercury;
    background: $c-concrete;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    @include space('padding', 'left');
    @include space('padding', 'right');
    padding-top: 1.5rem;

    @include bp(max-width, $bp-small) {
      padding-top: 2.5rem;
    }

    .shop-item {
      background: $c-white;
      height: 150px;
      margin-bottom: 15px;
      display: table;

      @include bp(min-width, $bp-xlarge) {
        width: calc(33.33% - 10px);
      }

      @include bp(max-width, $bp-xlarge) {
        width: calc(50% - 10px);
      }

      @include bp(max-width, $bp-small) {
        width: 100%;
        margin:0 auto 8px;
        height: auto;
      }

      &:hover {
        box-shadow: 0 0 20px 1px $c-mercury;
      }

      > a {
        display: table-cell;
        height: 100%;

        &:hover {
          text-decoration: none;
        }
      }

      &.exposition-room {
        .shop-item-pin {
          svg {
            path {
              fill: $c-curious-blue;
            }
          }
        }
      }

      .shop-item-pin {
        display: table-cell;
        position: relative;
        width: 98px;
        text-align: center;
        padding-top:3.6rem;
        @include bp(max-width, $bp-medium) {
          @include bp(min-width, $bp-small) {
            padding-top: 2.2rem;
          }
        }

        @include bp(max-width, $bp-large) {
          width: 78px;
        }

        @include bp(max-width, $bp-small) {
          width: 56px;
        }

        path {
          fill: $c-congress-blue;
        }

        .shop-item-letter {
          text-align: center;
          width: 23px;
          position: absolute;
          left: 38px;
          top: 45px;
          color: $c-white;
          font-weight:$semibold-font-weight;

          @include bp(max-width, $bp-large) {
            left: 28px;
          }
          @include bp(max-width, $bp-medium) {
            top: 30px;
          }
          @include bp(max-width, $bp-small) {
            top: 47px;
            left: 17px;
          }
        }
      }

      .shop-360-view {
        background: $c-curious-blue;
        display: table-cell;
        width:70px;
        text-align: center;
        vertical-align: top;
        font-weight: $light-font-weight;
        height: 100%;

        @include bp(max-width, $bp-small) {
          width: 45px;
        }

        a {
          color: $c-white;
          display: flex;
          width: 100%;
          height: 100%;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          &:hover {
            text-decoration: none;
          }
        }

        svg {
          margin-top:1rem;
        }

        &.no-content {
          background: none;
        }
      }

      .shop-item-description {
        display: table-cell;
        padding-top:3.2rem;
        vertical-align: top;
        color: $c-gray;
        @include font-size(1.3);
        letter-spacing: 0.03rem;
        font-weight: $light-font-weight;

        @include bp(max-width, $bp-xlarge) {
          padding-bottom: 2rem;
          padding-right: 1rem;
        }

        @include bp(max-width, $bp-small) {
          @include font-size(1);
          padding-bottom: 3.6rem;
        }

        .shop-item-title {
          font-weight: $bold-font-weight;
          color: $c-mine-shaft;
          width: 100%;
          display: block;
          @include font-size(1.4);
          margin: 0;

          @include bp(max-width, $bp-small) {
            @include font-size(1.2);
          }
        }

        .shop-item-subtitle {
          font-weight: $semibold-font-weight;
          width: 100%;
          margin-top: 1rem;
          display: block;
        }

        .shop-item-sign {
          white-space: nowrap;
        }
      }
    }
  }
}
.shop-items-map-container {
  border: 1px solid $c-mercury;
  position: relative;

  #map {
    height: 500px;
    @include bp(max-width, $bp-small) {
      height: 290px;
    }
  }

  .map-hint {
    position: absolute;
    left: 0;
    bottom: 0;
    border-top: 1px solid $c-mercury;
    border-right: 1px solid $c-mercury;
    background: $c-white;
    padding: 2.1rem 2.4rem 1.4rem;

    @include bp(max-width, $bp-small) {
      padding: 1rem 1rem 0 0.8rem;
    }

    span {
      display: inline-block;
      vertical-align: top;
      &:before {
        content: '\00b7';
        color: #004883;
        @include font-size(9);
        line-height: 29px;
        margin-right: 6px;
        height: 20px;
        display: inline-block;
        vertical-align: top;

        @include bp(max-width, $bp-small) {
          font-size: 8rem;
          line-height: 19px;
          height: auto;
        }
      }
    }

    .map-hint-shops {
      &:before {
        color: $c-congress-blue;
      }
    }
    .map-hint-expo {
      margin-right: 1.5rem;
      &:before {
        color: $c-curious-blue;
      }
    }
  }
}
.shops-bottom {
  border-left: 1px solid $c-mercury;
  border-right: 1px solid $c-mercury;
  border-top: 1px solid $c-mercury;

  .shops-logo {
    width: 58%;
    justify-content: space-between;
    display: flex;
    box-sizing: border-box;
    padding-left: 60px;
    @include bp(min-width, $bp-medium) {
      padding-left:0;
      justify-content: flex-start;
      padding-top: 6rem !important;
      padding-bottom: 2.8rem !important;
      width:80%;
      margin: auto;
      img:nth-child(1){
        height:4.2rem;
        width:auto;
      }
      img:nth-child(2){
        height:4.8rem;
        width:auto;
        padding-left: 62px;
      }
    }
    @include bp(max-width, $bp-medium) {
      @include bp(min-width, $bp-small) {
        width:100%;
        justify-content: center;
        padding-left: 0;
        img:nth-child(1){
          height:4.3rem;
          width:auto;
          padding-right: 3rem;
        }
        img:nth-child(2){
          height:4.8rem;
          width:auto;
          padding-left: 3rem;
        }
      }
    }
    @include bp(max-width, $bp-small) {
      justify-content: flex-start !important;
      padding-left: 2rem;
      padding-top: 1.7rem !important;
      padding-bottom: 1.7rem !important;
      img:nth-child(1){
        height:27px;
        width: auto;
      }
      img:nth-child(2){
        height:27px;
        width: auto;
        padding-left: 2.5rem;
      }
    }

    @include space('padding', 'top');
    @include space('padding', 'bottom');
  }

  .fullsize-page-seo {
    border-left: none;
    border-right: none;
    border-bottom: none;
    align-items: flex-start;
    padding-top: 8.3rem;
    padding-bottom: 5rem;

    @include bp(max-width, $bp-large) {
      flex-direction: column;
      @include space('padding', 'top');
      @include space('padding', 'bottom');
    }
  }
}

@include bp(max-width, $bp-xlarge) {
  .shop-pictures-container .shop-picture-control {
    width: $margin-xlarge;
  }
}
@include bp(max-width, $bp-large) {
  .shop-pictures-container .shop-picture-control {
    width: $margin-large;
  }
}
@include bp(max-width, $bp-medium) {
  .news-container .news-title-container h1 {
    text-align: center;
    width: 100%;
    max-width: 100%;
  }
  .shop-pictures-container .shop-picture-control {
    width: $margin-medium;
  }
  .shop-subcontent-container {
    display: block;
  }
  .shop-subcontent-container .shop-subtitle {
    margin-bottom: 15px;
  }

  .shop-opening-container .shop-opening {
    width: 100%;
    border-bottom: 1px solid $c-alto;
  }
}

.shop-subcontent-container{
  display:flex;
  align-items: flex-start;
  padding-top: 3.5rem;
  .comafranc-button{
    margin-top: 3rem;
  }
  svg{
    height:30px;
    width:auto;
    vertical-align: middle;
  }

  @include bp(min-width, $bp-xlarge) {
    min-height:391px;
  }
  @include bp(max-width, $bp-small) {
    padding-top: 2rem;
  }
  @include bp(max-width, $bp-xlarge) {
    flex-direction: column;
    padding-bottom:5.7rem;
    .banner-image {
      position: static;
      margin:0 auto;
    }
    @include bp(min-width, $bp-large) {
      .banner-title, .banner-description {
        left: 10%;
      }
    }
  }
  @include bp(max-width, $bp-xlarge) {
    @include bp(min-width, $bp-small) {
      padding-bottom: 5.4rem;
    }
  }
  @include bp(max-width, $bp-small) {
    padding-bottom: 2.2rem;
  }
  .shop-subcontent{
    &:nth-child(2){
      @include bp(max-width, $bp-xlarge) {
        @include bp(min-width, $bp-small) {
          display: block;
        }
      }
    }
    &:nth-child(1){
      @include bp(min-width, $bp-xlarge) {
        padding-bottom: 20px;
        padding-left: 6rem;
        padding-right: 6rem;
      }
      @include bp(max-width, $bp-medium) {
        @include bp(min-width, $bp-small) {
          padding-left: 4.5rem;
          padding-right: 4.5rem;
          padding-bottom: 4.5rem;
        }
      }
      h2{
        margin-bottom: 0;
        padding-bottom:4rem;
        padding-top: 2.2rem;
        font-size:24px;
        @include bp(max-width, $bp-small) {
          padding-top: 0;
          @include font-size(1.2);
          padding-bottom:2rem;
          margin-bottom: 0;
          padding-right:1.4rem;
          padding-left: 1.4rem;
        }
      }
    }

    position:relative;
    flex:1;
    @include bp(max-width, $bp-medium) {
      .shop-sucontent:nth-child(1){
        height:100%;
      }
    }
    @include bp(min-width, $bp-xlarge) {
      .shop-sucontent:nth-child(1){
        height:100%;
      }
    }
    .banner-image{
      position:absolute;
      top: 0; left: 0; right: 0; bottom: 0;
    }

    .banner-title, .banner-description{
      position:relative;
      width:50%;
      padding-left: 5%;
      padding-right: 5%;
      padding-top:5%;
      z-index:2;
      @include bp(max-width, $bp-xlarge) {
        @include bp(min-width, $bp-small) {
          padding-left: 10%;
          padding-right: 10%;
        }
      }

      @include bp(max-width, $bp-small) {
        padding-left: 1rem;
        padding-right: 0;
      }
    }
    .banner-title{
      font-weight:$bold-font-weight;
      font-size: 1.8rem;
      color:$c-congress-blue;
      text-transform: uppercase;
      @include bp(max-width, $bp-small) {
        @include font-size(1.2);
        letter-spacing: 0.03rem;
      }
    }
    .banner-description{
      @include bp(max-width, $bp-small) {
        @include font-size(1);
        letter-spacing: 0.02rem;
        padding-top: 10px;
        line-height: 1.2rem;
      }
    }
  }
}



