.catalogsearch-result-index {
  .page-title-wrapper {
    text-align: center;
  }

  .sidebar-main {
    @include bp(max-width, $bp-small) {
      padding-bottom: 2.3rem;
      background: $c-concrete;
    }
  }

  .columns {
    @include bp(max-width, $bp-small) {
      flex-direction: column;
    }

    .sidebar {
      @include bp(max-width, $bp-small) {
        width: 100%;
      }

      .search-sidebar {
        @include bp(max-width, $bp-small) {
          margin-bottom: 0;
        }
      }
    }

    .column.main {
      @include bp(max-width, $bp-small) {
        width: 100%;
      }
    }
  }
}
.search-filter-select {
  background: $c-concrete;
  color: $c-gray;
  margin-top: 1rem;
  border-radius: 50px;
  height: 34px;
}
.search-sidebar {
  background: $c-concrete;
  height: 100%;
  @include space('padding', 'left');
  @include space('padding', 'right');

  &.block.filter {
    margin-bottom: 0;
  }

  &.search-title {
    height: auto;
    @include space('padding', 'top');
  }

  .search-nb-results {
    font-weight: $bold-font-weight;
    @include font-size(1.8);
    color: $c-mine-shaft;
    text-transform: uppercase;
    margin-bottom: 4rem;

    @include bp(max-width, $bp-small) {
      @include font-size(1.2);
      margin-bottom: 0;
    }
  }

  .filter-content {
    a {
      color: $c-gray;
      margin-top: 1.1rem;
      margin-bottom: 1rem;
      display: block;

      &:hover {
        text-decoration: none;
        color: lighten($c-gray, 10%);

        .count {
          text-decoration: none;
          color: lighten($c-gray, 10%);
        }
      }
    }
  }

  .filter-current {
    font-weight: $semibold-font-weight;
  }
}