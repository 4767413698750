.findapro-quality-index {
  @include bp(max-width, $bp-medium) {
    .page-main {
      margin-top: 0;
    }
  }

  .page-title-wrapper {
    @include bp(max-width, $bp-large) {
      @include bp(min-width, $bp-small) {
        margin-top: 3rem;
      }
    }
    @include bp(min-width, $bp-large) {
      margin-top: 6rem;
    }
  }
}
.quality-container {

  overflow: hidden;

  .overlay {
    display: none;
    background: $c-black;
  }

  > picture img {
    display: block;

    @include bp(max-width, $bp-xlarge) {
      height: 100%;
      width: auto;
      max-width: none;
    }

    @include bp(max-width, $bp-medium) {
      @include bp(min-width, $bp-small) {
        max-height: 280px;
        width: 140%;
      }
    }
  }

  .quality-description, .quality-title {
    line-height: 22px;

    @include bp(max-width, $bp-small) {
      line-height: 16px;
    }
  }

  .quality-description-container {
    display: flex;
    justify-content: flex-end;
    color: $c-gray;
    letter-spacing: 0.04rem;
    border: 1px solid $c-mercury;

    @include bp(max-width, $bp-small) {
      border: none;
    }

    .quality-description-content {
      width: 60%;
      @include space('padding', 'top');
      @include space('padding', 'bottom');
      @include space('padding', 'right');
    }

    .quality_description {
      margin-bottom: 2rem;
    }

    .quality-subtitle {
      font-weight: $medium-font-weight;
    }
  }

  .qualities-list {
    ul {
      padding-left: 0;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 0;
    }

    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 33.3333333%;
      height: 380px;
      margin-bottom: 0;
      box-sizing: border-box;
      color: $c-gray;
      letter-spacing: 0.05rem;
      @include space('padding', 'top');
      @include space('padding', 'right');
      @include space('padding', 'bottom');
      @include space('padding', 'left');

      @include bp(max-width, $bp-small) {
        width: 50%;
      }

      &:nth-child(2) {
        border-top: 1px solid $c-mercury;

        svg {
          @include bp(max-width, $bp-small) {
            width: 70px;
          }
        }
      }
      &:nth-child(6) {
        border-right: 1px solid $c-mercury;
      }
      &:nth-child(10) {
        border-left: 1px solid $c-mercury;
      }
      &:nth-child(15) {
        border-right: 1px solid $c-mercury;
      }

      &.no-padding {
        padding: 0;
      }

      img {
        display: block;
        min-width: 100%;
      }

      &.quality-item {
        background: $c-concrete;
        align-items: flex-start;
        justify-content: flex-start;

        @include bp(max-width, $bp-small) {
          align-items: center;
          min-height: 142px;
          justify-content: space-between;
          padding-bottom: 2.5rem;
        }

        &.add-border {
          border-right: 1px solid $c-white;
        }
      }

      .quality-title {
        color: $c-congress-blue;
        @include font-size(1.8);

        @include bp(max-width, $bp-small) {
          @include font-size(1.2);
          text-align: center;
        }

        &::first-letter {
          font-weight: $bold-font-weight;
          @include font-size(2.2);

          @include bp(max-width, $bp-small) {
            @include font-size(1.9);
          }
        }
      }

      .quality-description {
        @include bp(max-width, $bp-small) {
          position: absolute;
          width: 90%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 10;
          padding: 2.5rem;
          background: $c-white;

          .cross-close {
            @include bp(max-width, $bp-small) {
              width: 10px;
              height: 10px;
              min-width: 0;
              float: right;
              margin-bottom: 2rem;
            }
          }

          .quality-title {
            @include bp(max-width, $bp-small) {
              text-align: left;
              margin-bottom: 2rem;
              clear: both;
            }
          }
        }
      }
    }
    svg {
      width: 75px;

      @include bp(max-width, $bp-small) {
        width: 45px;
      }

      path {
        fill: $c-mercury;
      }
    }
  }
}
.quality-hint {
  @include bp(max-width, $bp-small) {
    color: $c-congress-blue;
    border: 1px solid $c-alto;
    box-shadow: 0 0 20px 1px $c-mercury;
    border-radius: 50px;
    width: 25px;
    background: white;
    height: 25px;
    text-align: center;
    padding-top: 6px;
    padding-left: 1px;
  }
}


@include bp(max-width, $bp-xlarge) {
  .quality-container .qualities-list li {
    padding-left: 3rem;
    width: 50%;
    overflow: auto;
    clear: both;
    height: auto;
  }
  .quality-container .qualities-list li.quality-item {
    border-bottom: 1px solid white;
  }

  .quality-container .qualities-list li {
    &:nth-child(2) {
      border:none;
    }
    &:nth-child(6) {
      border:none;
      border-bottom: 1px solid $c-mercury;
    }
    &:nth-child(8) {
      border-bottom: 1px solid $c-mercury;
    }
    &:nth-child(10) {
      border:none;
    }
    &:nth-child(15) {
      border:none;
    }
  }
  .quality-container .quality-description-container .quality-description-content {
    width: 100%;
    @include space('padding', 'left');
  }
}
