.comafranc-local-sitemap-index {
  .page-title-wrapper {
    margin-bottom: 3.4rem;
    margin-top: 4.2rem;
    h1{
      width:100%;
      margin-left:0;
    }


    @include bp(max-width, $bp-small) {
      .page-title span{
        font-weight: $bold-font-weight !important;
      }
      margin-bottom: 2rem;
      margin-top: 0;
      padding-top: 21px;
      text-align: center;
    }
  }
}
.sitemap-container {
  border-left: 1px solid $c-mercury;
  border-right: 1px solid $c-mercury;
  border-top: 1px solid $c-mercury;
  @include bp(min-width, $bp-small) {
    @include space('padding', 'right');
    @include space('padding', 'left');
    @include space('padding', 'top');
    @include space('padding', 'bottom');
  }
  @include bp(max-width, $bp-small) {
    padding-bottom: 1rem;
    padding-left: 2.3rem;
    padding-right: 2.1rem;
  }

  a {
    color: $c-gray;
  }

  @include bp(max-width, $bp-small) {
    #sitemap-products{
      margin-bottom:0;
    }
  }


  .section-title-small{
    @include bp(min-width, $bp-medium) {
      > a {
        font-weight:$bold-font-weight;
        font-size: 2.4rem;
      }
    }
    &:first-child{

    }
    @include bp(max-width, $bp-small) {
      font-weight: $bold-font-weight;
      >a{
        @include font-size(1.4);
      }
      color: $c-mine-shaft;
      text-transform: uppercase;
      margin-bottom: 2rem;
      border-top: 1px solid $c-mercury;
      padding-top: 1.6rem;
      letter-spacing: 0.03rem;
      &:first-child{
        border-top:0;
      }

    }
  }

  .section-title, .section-title-visible {
    font-weight:$bold-font-weight;
    @include font-size(2.4);
    color: $c-mine-shaft;
    text-transform: uppercase;
    margin-bottom: 3rem;
    border-top: 1px solid $c-mercury;
    padding-top: 4rem;
    letter-spacing: 0.03rem;

    &:first-child {
      border: none;
      padding-top: 0;
    }

    @include bp(max-width, $bp-medium) {
      @include font-size(2.8);
    }

    @include bp(max-width, $bp-small) {
      @include font-size(1.3);
      padding-top: 1rem;
      margin-bottom: 1rem;
      justify-content: space-between;
      align-items: center;
      display: flex;
      font-weight:$bold-font-weight;
    }
  }

  .sub-subcategory-list{
    display:block;
  }

  > ul {
    display: flex;
    flex-wrap: wrap;
    letter-spacing: 0.01rem;

    @include bp(max-width, $bp-small) {
      flex-direction: column;
      flex-wrap: nowrap;
    }

    > li {
      width: 25%;
      margin-bottom: 10rem;

      @include bp(max-width, $bp-medium) {
        @include bp(min-width, $bp-small){
          width: 33.333%;
          &:first-child {
            margin-bottom: 4rem;
          }
          &:last-child {
            margin-bottom: 4rem;
          }
        }
      }

      @include bp(max-width, $bp-small) {
        width: 100%;
        margin-bottom: 1rem !important;
        padding-top: 1rem !important;
      }
    }
  }

  #sitemap-shops {
    border-bottom: 1px solid $c-mercury;
    padding-bottom: 4rem;
    letter-spacing: 0.01rem;

    @include bp(max-width, $bp-medium) {
      @include font-size(2.4);
      padding-bottom: 15px;
      li{
        @include font-size(1.4);
      }
    }

    @include bp(max-width, $bp-small) {
      @include font-size(1.2);
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: none;
    }

    li {
      width: 25%;
      margin-bottom: 1rem;

      @include bp(max-width, $bp-medium) {
        width: 50%;
      }

      @include bp(max-width, $bp-small) {
        width: 100%;
        margin-bottom: 0;
      }

      a {
        width:100%;
        display: block;

        @include bp(max-width, $bp-small) {
          padding-bottom: 0;
        }
      }
    }
  }

  ul {
    padding: 0;
  }

  .top-category-name {
    font-weight: $bold-font-weight;
    @include font-size(1.6);
    color: $c-mine-shaft;

    @include bp(max-width, $bp-medium) {
      @include font-size(2.4);
    }

    @include bp(max-width, $bp-small) {
      @include font-size(1.3);
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &:hover {
      @include bp(max-width, $bp-small) {
        text-decoration: none;
      }
    }
  }

  .category-name {
    font-weight:$bold-font-weight;
    font-size: 24px;
    text-transform: uppercase;
    @include bp(max-width, $bp-small) {
      font-size:1.4rem;
      .arrow-up{
        display:none;
      }
    }
  }

  .sub-category-name {
    text-transform:none ;
    margin-bottom: 0.5rem;
    font-weight:$bold-font-weight;
    font-size: 1.4rem;
    display:flex;
    flex-direction: column;




    @include bp(max-width, $bp-small) {
      padding-bottom: 1.1rem;
      margin-bottom: 0;
      padding-top: 0;
    }

    a{
      color:$c-mine-shaft;
      width:100%;
      @include bp(max-width, $bp-small) {
        @include font-size(1.3);
      }
      span{
        float: right;
      }
    }
    @include bp(min-width, $bp-small) {
      &:nth-child(1) {
        padding-top: 2.5rem;
      }
    }
    @include bp(max-width, $bp-small) {
      &:nth-child(1) {
        padding-top: 1rem;
      }
    }


    .sub-sub-category-name {
      margin-bottom: 0.5rem;

      font-size: 14px;
      a {
        color: $c-gray;
        font-weight: $regular-font-weight;
      }

      &:last-child {
        margin-bottom: 0.7px;
      }

      @include bp(max-width, $bp-small) {
        @include font-size(1.2);
        padding-bottom: 1.1rem;
        margin-bottom: 0;
        padding-top: 0;
        &:nth-child(1) {
          padding-top: 1.1rem;
        }
        &:last-child {
          padding-bottom: 0;
        }
      }

      @include bp(max-width, $bp-medium) {
        @include font-size(1.4);
      }

      @include bp(max-width, $bp-small) {
        @include font-size(1.2);
        padding-bottom: 1.1rem;
      }
    }
  }

  .sitemap-pages {
    margin-top: 5rem;

    @include bp(max-width, $bp-small) {
      margin-bottom: 0;
      margin-top: 0;
    }

    a {
      font-weight: $bold-font-weight;
      @include font-size(1.6);
      color: $c-mine-shaft;
      text-transform: uppercase;

      @include bp(max-width, $bp-medium) {
        @include font-size(1.6);
      }

      @include bp(max-width, $bp-small) {
        @include font-size(1.3);
      }
    }

    li {
      margin-bottom: 3rem;
      width: 25%;
      font-weight:$bold-font-weight;
      font-size: 16px;
      @include bp(max-width, $bp-medium) {
        width: 33%;
      }

      @include bp(max-width, $bp-small) {
        width: 100%;
        border-top: 1px solid $c-alto;
        padding-top: 1rem;
        margin-bottom: 1rem;
      }

      &:last-child {
        @include bp(max-width, $bp-small) {
          margin-bottom: 0;
        }
      }
    }
  }

  .sitemap-accordion, .section-title {
    @include bp(max-width, $bp-small) {
      display: none;
    }
  }

  .arrow-up {
    display: none;

    svg {
      width: 9px;
      height: auto;

      path {
        fill: $c-black;
      }
    }
  }

  .arrow-bottom {
    display: none;

    @include bp(max-width, $bp-small) {
      display: block;
    }

    svg {
      width: 9px;
      height: auto;

      path {
        fill: $c-black;
      }
    }
  }
}