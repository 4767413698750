.cms-index-index {
  background: $c-concrete;

  #maincontent {
    max-width: 1010px ;
    @include bp(max-width, $bp-large) {
      width: 100%;
    }
    @include bp(max-width, $bp-small) {
      margin-top: 0;
    }
  }
}
.portal-container {
  box-shadow: 0 3px 10px -2px $c-dusty-grey;

  @include bp(max-width, $bp-large) {
    padding-bottom: 20px;
  }
  @include bp(max-width, $bp-small) {
    padding-bottom: 0;
  }
}
.portal-button {
  background-color: $c-cerulean;
  text-transform: uppercase;
  border: 1px solid $c-cerulean;
  display: inline-block;
  padding: 20px 22px;
  text-align: center;
  transition: all 500ms ease;
  @include bp(max-width, $bp-small) {
    padding: 9px 8px;
  }

  span {
    color: $c-white;
    display: block;
    width: 100%;

    &.small-text {
      @include font-size(1.3);
      @include bp(max-width, $bp-small) {
        @include font-size(0.6);
      }
    }

    &.big-text {
      font-weight: $bold-font-weight;
      @include font-size(1.4);
      @include bp(max-width, $bp-small) {
        @include font-size(0.8);
      }
    }
  }

  &:hover {
    text-decoration: none;

    span {
      color: $c-cerulean;
    }

    background-color: $c-white;
  }
}
.portal-header {
  height: 225px;
  color: $c-white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include bp(max-width, $bp-small) {
    padding: 20px 10px;
    height:112px;
  }
  .title img{
    @include bp(max-width, $bp-small) {
      width:200px;
      height:auto;
    }
  }

  .subtitle {
    margin-top: 10px;
    letter-spacing: 0.03rem;
    @include font-size(1.5);

    @include bp(max-width, $bp-small) {
      @include font-size(1);
    }

  }
}
.portal-content {
  display: flex;
  justify-content: space-between;
  margin-top: 6px;
  background: $c-white;

  @include bp(max-width, $bp-large) {
    flex-direction: column;
    align-items: center;
  }
  @include bp(max-width, $bp-small) {
    border-right: 8px solid $c-concrete;
    border-left: 8px solid $c-concrete;
    margin-top: 0;
    border-top:8px solid $c-concrete;
  }

  .portal-content-column {
    width: 50%;
    text-align: center;
    padding-bottom: 50px;

    &:nth-child(1){
      border-right: 3px solid $c-concrete;
      @include bp(max-width, $bp-small) {
        border-bottom:10px solid $c-concrete;
      }
    }
    &:nth-child(2){
      border-left: 3px solid $c-concrete;
    }
    &:hover {
      text-decoration: none;
    }

    @include bp(max-width, $bp-large) {
      width: 90%;
      margin-bottom: 30px;
    }
    @include bp(max-width, $bp-small) {
      width: 100%;
      padding-bottom: 1.5rem;
      margin-bottom: 0;
    }

    .portal-content-bottom {
      @include bp(max-width, $bp-large) {
        display: flex;
        justify-content: space-around;
        align-items: center;
      }
      @include bp(max-width, $bp-small) {
        flex-direction: column;
      }
    }

    .portal-content-logo {
      margin: 30px 0;

      > img:nth-child(2) {
        margin-left: 1.5rem;
        @include bp(min-width, $bp-large) {
          height: 49px;
          width: auto;
        }
      }

      @include bp(max-width, $bp-small) {
        margin: 10px 0;
        img{
          width:90px;
          height:auto;
        }
      }
    }

    .portal-content-image {
      display: block;
      width: 100%;
    }
  }
}

.portal-footer {
  background: #19477e;
  text-align: center;
  padding: 25px 10px;
  margin-top: 60px;

  p {
    color: #ffffff;
    font-size: 20px;
  }
}
