.shop-map-tooltip {
  color: $c-mine-shaft;
  padding: 3rem 2.5rem;
  line-height:22px;
  @include font-size(1.4);

  @include bp(max-width, $bp-small) {
    @include font-size(1);
    padding-bottom: 1rem;
    padding-top: 1rem;
    line-height: 16px;
    min-height: 80px;
    align-items: center;
  }

  .map-tooltip-title {
    font-weight: $bold-font-weight;

    @include bp(max-width, $bp-small) {
      @include font-size(1.2);
      display: block;
    }
  }

  .map-tooltip-hint {
    @include font-size(1.2);

    @include bp(max-width, $bp-small) {
      @include font-size(0.9);
      display: block;
      margin-top: 1rem;
    }
  }
  a {
    @include font-size(1.2);
    font-weight: $bold-font-weight;
    color: $c-congress-blue;
    @include bp(min-width, $bp-large) {
      position: absolute;
      bottom: 38px;
    }
  }

  img {
    margin-left:3rem;
    border: 1px solid $c-mercury;
  }

  .shop-map-tooltip-column {
    &:nth-child(1){
      padding-left: 20px;
    }
    @include bp(max-width, $bp-small) {
      padding-left: 0 !important;
      line-height: 16px;
    }

    &:last-child {
      align-self: center;
    }
  }

  .shop-items-map-container & {
    padding-left: 1rem;
    padding-right: 0;
    display: flex;
  }
}
.shop-view-details {
  .shop-map-tooltip {
    a {
      position:static !important;
    }
  }
}
.shop-index-index{
  .shop-map-tooltip-column {
    @include bp(max-width, $bp-small) {
      padding-left: 0 !important;
      line-height: 16px;
      .map-tooltip-address{
        display:block;
      }
      br {
        display:none;
      }
    }
  }
}