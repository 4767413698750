.cms-mentions-legales-html {
  .breadcrumbs {
    display: none;
  }
  .page-title-wrapper {
    text-align: center;
    margin-bottom: 5rem;

    @include bp(max-width, $bp-small) {
      margin-bottom: 2rem;
      margin-top: 0;
      padding-top: 21px;
    }
  }

  .column.main {
    border-top: 1px solid $c-mercury;
    border-left: 1px solid $c-mercury;
    border-right: 1px solid $c-mercury;
    @include bp(max-width, $bp-small) {
      border-right: 0;
      border-left: 0;
    }
  }

  table {
    tbody {
      td {
        width: 50%;
        @include space('padding', 'top');
        @include space('padding', 'bottom');

        @include bp(max-width, $bp-medium) {
          width: 100%;
          padding: 0;
          display: block;
        }
      }
    }
  }

  .section-column{
    @include bp(max-width, $bp-medium) {
      @include bp(min-width, $bp-small) {
        padding-right: 4.7rem;
        padding-left: 4.7rem;
      }
    }
  }

  .terms-section {
    margin-bottom: 3rem;
    @include space('padding', 'right');
    @include space('padding', 'left');




    &.section-inline {
      @include space('padding', 'right');
      @include space('padding', 'left');
      @include space('padding', 'top');
      @include space('padding', 'bottom');
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $c-mercury;

      @include bp(max-width, $bp-small) {
        flex-direction: column;
        padding-right: 0;
        padding-left: 0;
        margin-right: 4.7rem;
        margin-left: 4.7rem;
        @include bp(max-width, $bp-small) {
          margin-right: 3rem;
          margin-left: 3rem;
        }
      }

      .terms-section-title {
        width: 35%;
        @include bp(max-width, $bp-medium) {
          @include bp(min-width, $bp-small) {
            width:50%;
          }
        }
        @include bp(max-width, $bp-small) {
          margin-bottom: 1rem;
          width:100%;
        }
      }
      .terms-section-content {
        width: 64%;
        @include bp(max-width, $bp-medium) {
          @include bp(min-width, $bp-small) {
            width:50%;
          }
        }
        @include bp(max-width, $bp-small) {
          width:100%;
        }
      }
    }

    .terms-section-content {
      color: $c-gray;
    }
  }
}