.navigation, .breadcrumbs .items, .page-header .header.panel, .header.content, .footer.content, .page-wrapper > .widget, .page-wrapper > .page-bottom, .block.category.event, .page-main, .homepage-title {
  max-width: $bp-xlarge;
}
.top-container {
  padding-left:0;
  padding-right:0;
}
.news-container, .footer.content, .fullsize-page, .page-main {
  max-width: 1446px;
  margin: 0 auto;
  padding: 0;

  @include bp(max-width, $bp-small) {
    width: 100%;
  }
}
.page-layout-2columns-left {
  .columns {
    display: flex;
    flex-wrap: nowrap;

    .column.main {
      width: 74%;
      float: none;
    }
    .sidebar {
      padding-right: 0;
      width:25%;
      float: none;
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }
}

.visible-md {
  display: none !important;
  @include bp(max-width, $bp-medium) {
    display: block !important;
  }
}
.hidden-md {
  @include bp(max-width, $bp-medium) {
    display: none !important;
  }
}
div {
  box-sizing: border-box;
}
.page-main {
  margin:0 auto;
  @include bp(max-width, $bp-xxl) {
    width: $bp-xlarge;
  }
  @include bp(max-width, $bp-xlarge) {
    width: $bp-large;
  }
  @include bp(max-width, $bp-large) {
    width: $bp-medium;
  }
  @include bp(max-width, $bp-medium) {
    width: 100%;
  }
}

.img-responsive {
  width: 100%;
}

.hidden {
  display: none !important;
}

@include bp(max-width, $bp-medium) {
  body {
    .page-header {
      position: fixed;
      top: 0;
      left: 0;
      background: white;
      z-index:100;
    }
  }
}
.hidden-small {
  @include bp(max-width, $bp-small) {
    display: none !important;
  }
}
.visible-small {
  display: none !important;

  @include bp(max-width, $bp-small) {
    display: block !important;
  }
}
.visible-large {
  display: none !important;

  @include bp(max-width, $bp-large) {
    display: block !important;
  }
}
.hidden-large {
  @include bp(max-width, $bp-large) {
    display: none !important;
  }
}

.back-link {
  color: $c-mine-shaft;
  font-weight: $bold-font-weight;

  &:visited {
    color: $c-mine-shaft;
  }

  &:hover {
    color: lighten($c-mine-shaft, 30%);
    text-decoration: none;
  }
}
